import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { SkeletonText } from 'app/components/skeleton';
import ContentBox from 'app/components/ContentBox';
import CoopTeamPane from 'app/components/coop-ui/CoopTeamPane';
import CoopObjectivesPane from 'app/components/coop-ui/CoopObjectivesPane';

import styles from './styles.less';

const ProcedureRow = ({ name, status, score }) => (
  <div className={styles.procedureRow}>
    <div className={styles.name}>{name}</div>
    <div className={styles.status}>{status}</div>
    <div className={styles.score}>{score}</div>
  </div>
);

const ProcedureRowSkeleton = () => (
  <div className={styles.procedureRow}>
    <div className={styles.name}>
      <div className={styles.skeleton}>
        <SkeletonText extraClassName="w100" />
      </div>
    </div>
    <div className={styles.status} />
    <div className={styles.score}>
      <div className={styles.skeleton}>
        <SkeletonText extraClassName="w100" />
      </div>
    </div>
  </div>
);

const ProcedureTotalRow = ({ procedures }) => {
  const { t } = useTranslation();

  let totalScore = 0;
  if (procedures) {
    procedures.forEach((procedure) => {
      totalScore += procedure.score;
    });
  }
  return (
    <div className={styles.procedureTotalRow}>
      <div className={styles.name}>&nbsp;</div>
      <div className={styles.status}>{t('TOTAL')}</div>
      <div
        className={classNames(
          styles.score,
          { '-negative': totalScore < 0 },
          { '-positive': totalScore >= 0 },
        )}
      >
        {totalScore}
      </div>
    </div>
  );
};

const ProcedureList = ({ procedures }) => {
  const { t } = useTranslation();

  if (!procedures) {
    return [...Array(2)].map((_, i) => <ProcedureRowSkeleton key={i} />);
  }

  return (
    <>
      {procedures.map((procedure) => (
        <ProcedureRow key={procedure.id} {...procedure} />
      ))}
      {procedures.length === 0 && <ProcedureRow name={t('No Penalties Deducted!')} score={0} />}
      <ProcedureTotalRow procedures={procedures} />
    </>
  );
};

const ProcedurePane = ({ title, procedures }) => {
  return (
    <div className={styles.procedures}>
      <ContentBox title={title}>
        <div className={styles.procedureTable}>
          <ProcedureList procedures={procedures} />
        </div>
      </ContentBox>
    </div>
  );
};

const RankingPane = ({ game }) => {
  const scoreClass = game.coop_score >= 100 ? styles.perfect : styles.imperfect;
  const { t } = useTranslation();
  return (
    <div className={styles.ranking}>
      <div className={styles.score}>
        <p>
          {t('Total:')} <span className={scoreClass}>{game.coop_score}</span>
          /100
        </p>
        <p>
          {t('Ranking:')} {game.coop_rank}
        </p>
      </div>
    </div>
  );
};

const GameDetailCoop = ({ game }) => {
  const { t } = useTranslation();
  const { players, procedures, objectives } = game;

  // collect penalties and bonuses
  const penalties = [];
  const bonuses = [];
  (procedures || []).forEach((procedure) => {
    if (procedure.is_penalty && procedure.score < 0) {
      penalties.push(procedure);
    } else if (!procedure.is_penalty) {
      bonuses.push(procedure);
    }
  });

  return (
    <div className={styles.frame}>
      <div className={styles.pane}>
        <CoopObjectivesPane extraClassName={styles.objectives} objectives={objectives} />
        <CoopTeamPane extraClassName={styles.team} players={players} />
      </div>
      <div className={classNames(styles.pane, styles.totals)}>
        <ProcedurePane title={t('Bonuses')} procedures={procedures ? bonuses : null} />
        <ProcedurePane title={t('Penalties')} procedures={procedures ? penalties : null} />
        <RankingPane game={game} />
      </div>
    </div>
  );
};

export default GameDetailCoop;
