import React, { useEffect, useMemo, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import ChapterView from 'app/views/ChapterView';
import CountryFlag from 'app/components/CountryFlag';
import Button from 'app/components/Button';
import { parseQuery } from 'app/utils/url';
import { convertQueryToFilters, setFilters } from 'app/store/serverList';
import { ModalTypes, setModal } from 'app/store/modal';
import { endpoints, useGetServersQuery, util as apiUtil } from 'app/api';
import { setPageMeta } from 'app/store/meta';

import styles from './styles.less';

const resolveObjKey = (obj, path) => {
  // https://stackoverflow.com/a/22129960
  return path.split('.').reduce((prev, curr) => (prev ? prev[curr] : undefined), obj);
};

const ServerRowEmpty = () => (
  <div className={styles.serverRowEmpty}>
    <div className={styles.serverPassworded}></div>
    <div className={styles.serverHostname}></div>
    <div className={styles.serverPlayers}></div>
    <div className={styles.serverGametype}></div>
    <div className={styles.serverMap}></div>
    <div className={styles.serverAddress}></div>
    <div className={styles.serverGame}></div>
    <div className={styles.serverVer}></div>
    <div className={styles.serverLocation}></div>
  </div>
);

const ServerRow = ({ server }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { ip, port } = server;
  const location = `/servers/${ip}:${port}/`;

  return (
    <div onClick={() => navigate(location)} className={styles.serverRow}>
      <div
        title={server.status.passworded ? t('Locked') : ''}
        className={server.status.passworded ? styles.serverPasswordedIcon : styles.serverPassworded}
      >
        &nbsp;
      </div>
      <div className={styles.serverHostname}>
        {/* this link only exists for indexing purpose */}
        <Link
          to={location}
          dangerouslySetInnerHTML={{ __html: server.status.hostname_html }}
          onClick={(e) => e.preventDefault()}
        />
      </div>
      <div className={styles.serverPlayers}>
        {server.status.player_num}/{server.status.player_max}
      </div>
      <div className={styles.serverGametype}>{server.status.gametype}</div>
      <div className={styles.serverMap}>{server.status.mapname}</div>
      <div className={styles.serverAddress}>
        {ip}:{port}
      </div>
      <div className={styles.serverGame}>{server.status.gamename}</div>
      <div className={styles.serverVer}>{server.status.gamever}</div>
      <div className={styles.serverLocation}>
        <CountryFlag iso={server.country} name={server.country_human} />
      </div>
    </div>
  );
};

const ServerTable = () => {
  const { t } = useTranslation();

  const filters = useSelector((state) => state.serverList.filters);
  const { data: servers } = useGetServersQuery(filters, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 3000,
  });

  const [ordering, setOrdering] = useState({
    by: 'status.player_num',
    direction: -1,
  });

  const setOrderBy = (field) => {
    const newOrderDirection = ordering.by === field ? ordering.direction * -1 : 1;
    setOrdering({
      by: field,
      direction: newOrderDirection,
    });
  };

  return (
    <div className={styles.servers}>
      <div className={styles.serverTable}>
        <div className={styles.serverTableHeader}>
          <div className={styles.serverPassworded} onClick={() => setOrderBy('status.passworded')}>
            &nbsp;
          </div>
          <div className={styles.serverHostname} onClick={() => setOrderBy('status.hostname')}>
            {t('Server Name')}
          </div>
          <div className={styles.serverPlayers} onClick={() => setOrderBy('status.player_num')}>
            {t('Players')}
          </div>
          <div className={styles.serverGametype} onClick={() => setOrderBy('status.gametype')}>
            {t('Game Type')}
          </div>
          <div className={styles.serverMap} onClick={() => setOrderBy('status.mapname')}>
            {t('Map Name')}
          </div>
          <div className={styles.serverAddress} onClick={() => setOrderBy('ip')}>
            {t('IP Address')}
          </div>
          <div className={styles.serverGame} onClick={() => setOrderBy('status.gamename')}>
            {t('Mod')}
          </div>
          <div className={styles.serverVer} onClick={() => setOrderBy('status.gamever')}>
            {t('Ver')}
          </div>
          <div className={styles.serverLocation} onClick={() => setOrderBy('country')}>
            &nbsp;
          </div>
        </div>
        {servers
          ? servers
              .slice()
              .sort((a, b) => {
                let aParam = resolveObjKey(a, ordering.by);
                let bParam = resolveObjKey(b, ordering.by);
                // compare numerically
                if (!isNaN(aParam)) {
                  bParam = bParam || 0;
                  return (aParam - bParam) * ordering.direction;
                }
                // compare alphabetically
                aParam = (aParam || '').toString();
                bParam = (bParam || '').toString();
                return aParam.localeCompare(bParam) * ordering.direction;
              })
              .map((server) => <ServerRow key={server.id} server={server} />)
          : null}
        <ServerRowEmpty />
      </div>
    </div>
  );
};

const ServerActionBar = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.serverList.filters);
  // in server filters form, a filter is considered applied
  // only when a neighboring checkbox is checked
  const appliedFiltersCount = useMemo(() => {
    return Object.values(filters).filter((value) => value === true).length;
  }, [filters]);
  return (
    <div>
      <div className={styles.actionBar}>
        <div className={styles.button}>
          <Button
            title={t('Add Server')}
            style="white"
            onClick={() => dispatch(setModal(ModalTypes.ADD_SERVER))}
          />
        </div>
        <div className={styles.button}>
          <Button
            title={t('Filters')}
            style="white"
            counter={appliedFiltersCount}
            onClick={() => dispatch(setModal(ModalTypes.FILTER_SERVERS))}
          />
        </div>
      </div>
    </div>
  );
};

const ServerList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(preparePageMetaAction());
  }, [dispatch]);

  return (
    <ChapterView title={t('Servers')} backgroundImage="/static/images/maps/background/servers.jpg">
      <div className={styles.container}>
        <ServerTable />
        <ServerActionBar />
      </div>
    </ChapterView>
  );
};

const preparePageMetaAction = () => {
  return setPageMeta({
    title: 'Server Browser · Monitoring Online Game Operations',
    description:
      'Monitor online game operations in SWAT 4 through the server browser. ' +
      'Gain insight into server details, such as player numbers and current missions, ' +
      'to select the most suitable server for your next strategic engagement.',
  });
};

export async function prepareServerState(match, req, store) {
  const queryFilters = convertQueryToFilters(parseQuery(req.url));
  store.dispatch(setFilters(queryFilters));
  store.dispatch(endpoints.getServers.initiate(queryFilters));
  await Promise.all(store.dispatch(apiUtil.getRunningQueriesThunk()));
  store.dispatch(preparePageMetaAction());
}

export default ServerList;
