const trueStrings = ['y', 'yes', 'true', 't'];
const falseStrings = ['n', 'no', 'false', 'f'];

export function normalizeBoolean(any) {
  if (typeof any === 'string') {
    if (trueStrings.includes(any)) {
      return true;
    }
    if (falseStrings.includes(any)) {
      return false;
    }
  }
  return any;
}

export function toBoolean(any) {
  return Boolean(normalizeBoolean(any));
}
