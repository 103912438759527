import React from 'react';

import ServerBrowserMock, { BrowserMockState } from './ServerBrowserMock';
import MainMenuMock, { MenuMockState } from './MainMenuMock';
import LoadingScreenMock from './LoadingScreenMock';
import styles from './styles.less';

export const TutorialState = {
  MAIN_MENU_OPEN: 0,
  MAIN_MENU_JOIN_GAME: 1,
  SERVER_BROWSER_OPEN: 2,
  SERVER_BROWSER_FORM_OPEN: 3,
  SERVER_BROWSER_FORM_ENTER: 4,
  SERVER_BROWSER_FORM_SUBMIT: 5,
  LOADING_SCREEN: 6,
};

const TutorialToBrowserStateMapping = {
  [TutorialState.SERVER_BROWSER_OPEN]: BrowserMockState.INITIAL,
  [TutorialState.SERVER_BROWSER_FORM_OPEN]: BrowserMockState.DIRECT_FORM_OPEN,
  [TutorialState.SERVER_BROWSER_FORM_ENTER]: BrowserMockState.DIRECT_FORM_ENTER,
  [TutorialState.SERVER_BROWSER_FORM_SUBMIT]: BrowserMockState.DIRECT_FORM_SUBMIT,
};

const TutorialToMenuStateMapping = {
  [TutorialState.MAIN_MENU_OPEN]: MenuMockState.INITIAL,
  [TutorialState.MAIN_MENU_JOIN_GAME]: MenuMockState.SELECT_JOIN_GAME,
};

const ServerDetailJoinTutorial = ({ state, address }) => {
  const renderStep = () => {
    switch (state) {
      case TutorialState.MAIN_MENU_OPEN:
      case TutorialState.MAIN_MENU_JOIN_GAME:
        return <MainMenuMock state={TutorialToMenuStateMapping[state]} />;
      case TutorialState.LOADING_SCREEN:
        return <LoadingScreenMock />;
      case TutorialState.SERVER_BROWSER_OPEN:
      case TutorialState.SERVER_BROWSER_FORM_OPEN:
      case TutorialState.SERVER_BROWSER_FORM_ENTER:
      case TutorialState.SERVER_BROWSER_FORM_SUBMIT:
        return <ServerBrowserMock state={TutorialToBrowserStateMapping[state]} address={address} />;
      default:
        return null;
    }
  };

  return <div className={styles.container}>{renderStep()}</div>;
};

export default ServerDetailJoinTutorial;
