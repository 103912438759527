import React from 'react';

import classNames from 'classnames';

import styles from './styles.less';

const ContentBox = ({ title, children, scrollable, bordered }) => (
  <section
    className={classNames(styles.contentBox, { '-scrollable': scrollable, '-bordered': bordered })}
  >
    {title && <header>{title}</header>}
    <article>{children}</article>
  </section>
);

export default ContentBox;
