import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { setPageMeta } from 'app/store/meta';
import ChapterView from 'app/views/ChapterView';

const Search = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(preparePageMetaAction());
  }, [dispatch]);

  return (
    <ChapterView backgroundImage="/static/images/maps/background/search.jpg">
      <div>{t('Work in progress. Come back later!')}</div>
    </ChapterView>
  );
};

const preparePageMetaAction = () => {
  return setPageMeta({
    title: 'Search · Operational Intelligence',
  });
};

export const prepareServerState = async (match, req, store) => {
  store.dispatch(preparePageMetaAction());
};

export default Search;
