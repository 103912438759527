import React from 'react';
import { hydrateRoot } from 'react-dom/client';
import { Provider } from 'react-redux';

import 'normalize.css';
import 'app/assets/styles/fonts.css';
import 'app/assets/styles/variables.less';
import 'app/assets/styles/media.less';
import 'app/assets/styles/base.less';
import 'app/i18n';
import BrowserApp from 'app/browser/app';
import configureStore from 'app/store';

hydrateRoot(
  document.getElementById('root'),
  <Provider store={configureStore()}>
    <BrowserApp />
  </Provider>,
);
