import dayjs from 'app/dayjs';

import { setPageMeta } from 'app/store/meta';

const getMetaTitleForGame = (game, settings) => {
  const dateFormatted = dayjs.utc(game.date_finished).format(settings.MOMENT_DATE_FORMAT);

  switch (game.gametype_slug) {
    case 'co-op':
      return `${game.map.name} · CO-OP Mission on ${dateFormatted}`;
    default:
      return `${game.map.name} · ${game.gametype} Match on ${dateFormatted}`;
  }
};

const getMetaDescriptionForGame = (game, settings) => {
  const dateFormatted = dayjs.utc(game.date_finished).format(settings.MOMENT_DATE_FORMAT);
  const serverHostname = game.server.name_clean || game.server.address;
  switch (game.gametype_slug) {
    case 'rapid-deployment':
      return (
        `Investigate the high-pressure encounter on ${game.map.name} ` +
        `played on ${dateFormatted} at ${serverHostname}. ` +
        `Assess SWAT's proficiency in locating and neutralizing explosive devices, ` +
        `and Suspects' ability to maintain control, ` +
        `while refining your tactics for similar situations`
      );
    case 'vip-escort':
      return (
        `Review the high-stakes operation on ${game.map.name} ` +
        `conducted on ${dateFormatted} at ${serverHostname}. ` +
        `Examine SWAT's ability to safeguard the VIP and neutralize threats, ` +
        `while Suspects attempt to capture and eliminate the target. ` +
        `Learn from this critical engagement to enhance your performance.`
      );
    case 'smash-and-grab':
      return (
        `Examine the fast-paced heist operation on ${game.map.name} ` +
        `played on ${dateFormatted} at ${serverHostname}. ` +
        `Observe SWAT's efforts to prevent Suspects from securing the briefcase and escaping, ` +
        `while Suspects employ cunning tactics to outmaneuver their opponents. ` +
        `Learn from this high-stakes encounter to enhance your strategic thinking.`
      );
    case 'co-op':
      return (
        `Analyze the coordinated effort on ${game.map.name} ` +
        `carried out on ${dateFormatted} at ${serverHostname}. ` +
        `Assess the effectiveness of team dynamics, decision-making, ` +
        `and strategy in tackling complex scenarios, ` +
        `refining your approach for successful SWAT 4 group missions.`
      );
    default:
      return (
        `Investigate the hostile confrontation on ${game.map.name} ` +
        `played on ${dateFormatted} at ${serverHostname}. ` +
        `Observe SWAT's tactics in apprehending or neutralizing Suspects, ` +
        `while Suspects strive to defend their position. ` +
        `Learn from this engagement to enhance your strategic approach in future missions.`
      );
  }
};

export const preparePageMetaAction = (game, settings) => {
  if (!game?.map || !game?.server) {
    return setPageMeta({
      title: 'Debriefing Report',
    });
  }

  return setPageMeta({
    title: getMetaTitleForGame(game, settings),
    description: getMetaDescriptionForGame(game, settings),
  });
};
