import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'app/components/Error';
import RoundTimer from 'app/components/RoundTimer';
import ChapterView from 'app/views/ChapterView';
import LoadingScreen from 'app/components/LoadingScreen';
import Button from 'app/components/Button';
import { endpoints, useGetServerQuery, util as apiUtil } from 'app/api';
import { initTicker, stopTicker } from 'app/store/serverDetail';
import { Server } from 'app/models';

import styles from './styles.less';
import { preparePageMetaAction } from './meta';

const ActionBar = ({ server: serverObj }) => {
  const { t } = useTranslation();

  const server = new Server(serverObj);

  return (
    <div className={styles.lowerButtonBar}>
      <div className={styles.buttonContainer}>
        <Button
          style="button"
          linkTo={server.getLeaderboardLink()}
          title={t('Leaderboard')}
          disabled
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button style="button" linkTo={server.getLink()} title={t('Scores')} />
      </div>
      <div className={styles.buttonContainer}>
        <Button style="right" linkTo={server.getJoinLink()} title={t('Join')} />
      </div>
    </div>
  );
};

const GameTime = () => {
  const { t } = useTranslation();
  const remainingTime = useSelector((state) => state.serverDetail.remainingTime);

  if (!remainingTime || remainingTime < 0) {
    return null;
  }

  return (
    <div className={styles.roundTimer}>
      <RoundTimer title={t('Time remaining')} seconds={remainingTime} />
    </div>
  );
};

const GameInfo = ({ server }) => {
  if (!server.status) {
    return null;
  }
  return (
    <div className={styles.gameInfo}>{`${server.status.gamename} ${server.status.gamever}`}</div>
  );
};

const DisplayName = ({ server }) => {
  const { t } = useTranslation();
  const params = useParams();
  const displayName = server.status?.hostname_clean || server.name_clean || params.address;
  return (
    <div>
      <div>{displayName}</div>
      {server.status?.round_max ? (
        <div className={styles.roundInfo}>
          {t('Round')}: {server.status.round_num}/{server.status.round_max}
        </div>
      ) : null}
    </div>
  );
};

const ServerNotFoundError = () => {
  const { t } = useTranslation();
  return <Error message={t('The connection to the server has failed')} url="/servers/" />;
};

const ServerDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();

  const {
    data: server,
    isLoading,
    error,
  } = useGetServerQuery(params.address, {
    refetchOnMountOrArgChange: true,
    pollingInterval: 3000,
  });

  useEffect(() => {
    dispatch(preparePageMetaAction(server));
  }, [server, dispatch]);

  useEffect(() => {
    dispatch(initTicker());
    return () => {
      dispatch(stopTicker());
    };
  }, [dispatch]);

  if (error) {
    return <ServerNotFoundError />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  const backgroundImage = server.status?.mapname_background
    ? server.status.mapname_background
    : '/static/images/maps/background/servers.jpg';

  return (
    <ChapterView
      backgroundImage={backgroundImage}
      navBar={<Button style="exit" linkTo="/servers/" title={t('Disconnect')} />}
      headerMiddle={<DisplayName server={server} />}
      footerLeftLower={<GameInfo server={server} />}
      footerMiddle={<GameTime />}
      footerRightUpper={<ActionBar server={server} />}
    >
      <Outlet />
    </ChapterView>
  );
};

export const prepareServerState = async (match, req, store) => {
  const { address } = match.params;
  store.dispatch(endpoints.getServer.initiate(address));
  await Promise.all(store.dispatch(apiUtil.getRunningQueriesThunk()));
  // get the data from the store to configure the page meta
  const state = store.getState();
  const result = endpoints.getServer.select(address)(state);
  const { data: server } = result;
  store.dispatch(preparePageMetaAction(server));
};

export default ServerDetail;
