import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.less';

export const FormInputField = (props) => {
  const { label, name, placeholder, type, onChange, extraClassName, ...otherProps } = props;
  return (
    <div className={styles.fieldRow}>
      {label ? <div className={styles.fieldLabel}>{label}</div> : null}
      <div className={styles.fieldInputContainer}>
        <input
          name={name}
          placeholder={placeholder}
          type={type || 'text'}
          onChange={onChange}
          className={extraClassName}
          {...otherProps}
        />
      </div>
    </div>
  );
};

export const FormSelectField = ({ label, name, value, options, onChange, disabled }) => (
  <div className={styles.fieldRow}>
    <div className={styles.fieldLabel}>{label}</div>
    <div className={styles.fieldSelectContainer} data-disabled={disabled}>
      <select onChange={onChange} name={name} value={value} disabled={disabled}>
        {options.map((option) => {
          let value, title;
          if (Array.isArray(option)) {
            [value, title] = option;
          } else {
            value = title = option;
          }
          return (
            <option key={value} value={value} disabled={!!disabled}>
              {title}
            </option>
          );
        })}
      </select>
    </div>
  </div>
);

export const FormCheckboxSelectField = ({
  label,
  name,
  value,
  options,
  checkboxName,
  checkboxValue,
  onChange,
}) => (
  <div className={styles.fieldRow}>
    <div className={styles.fieldLabel}>{label}</div>
    <div className={styles.fieldCheckboxSelectContainer}>
      <div className={styles.fieldCheckboxContainer}>
        <label>
          <input name={checkboxName} type="checkbox" checked={checkboxValue} onChange={onChange} />
          <span />
        </label>
      </div>
      <div className={styles.fieldSelectContainer}>
        <select name={name} value={value} onChange={onChange}>
          {options.map((option) => (
            <option key={option[0]} value={option[0]}>
              {option[1]}
            </option>
          ))}
        </select>
      </div>
    </div>
  </div>
);

export const FormCheckboxField = ({ name, label, value, onChange }) => (
  <div className={styles.fieldRow}>
    <div className={styles.fieldLabel}>{label}</div>
    <div className={styles.fieldCheckboxContainer}>
      <label>
        <input name={name} checked={value} type="checkbox" onChange={onChange} />
        <span />
      </label>
    </div>
  </div>
);

export const FormDateField = (props) => {
  const { yearName, yearValue, monthName, monthValue, dayName, dayValue, onChange } = props;
  const { t } = useTranslation();

  const initialYear = 2007;
  const currentYear = new Date().getFullYear();
  const yearOptions = [
    ['', t('Any')],
    ...new Array(currentYear - initialYear + 1)
      .fill()
      .map((v, i) => i + initialYear)
      .reverse(),
  ];
  const monthOptions = [
    ['', t('Any')],
    [1, t('January')],
    [2, t('February')],
    [3, t('March')],
    [4, t('April')],
    [5, t('May')],
    [6, t('June')],
    [7, t('July')],
    [8, t('August')],
    [9, t('September')],
    [10, t('October')],
    [11, t('November')],
    [12, t('December')],
  ];
  const dayOptions = [['', t('Any')], ...new Array(31).fill().map((v, index) => index + 1)];

  return (
    <div className={styles.fieldRow}>
      <div className={styles.fieldDatetimeContainer}>
        <div className={styles.fieldDatetimeYear}>
          <FormSelectField
            label={t('Year')}
            name={yearName}
            value={yearValue}
            options={yearOptions}
            onChange={onChange}
          />
        </div>
        <div className={styles.fieldDatetimeMonth}>
          <FormSelectField
            label={t('Month')}
            name={monthName}
            value={monthValue}
            options={monthOptions}
            onChange={onChange}
          />
        </div>
        <div className={styles.fieldDatetimeDay}>
          <FormSelectField
            label={t('Day')}
            name={dayName}
            value={dayValue}
            options={dayOptions}
            onChange={onChange}
          />
        </div>
      </div>
    </div>
  );
};
