import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetGameQuery } from 'app/api';
import GameDetailCoop from 'app/views/GameDetail/GameDetailScore/GameDetailCoop';
import GameDetailVersus from 'app/views/GameDetail/GameDetailScore/GameDetailVersus';

const GameDetailScore = () => {
  const { gameId } = useParams();
  const { data: game, isLoading } = useGetGameQuery(gameId);

  if (isLoading) {
    return null;
  }

  if (game.gametype_slug === 'co-op') {
    return <GameDetailCoop game={game} />;
  } else {
    return <GameDetailVersus game={game} />;
  }
};

export default GameDetailScore;
