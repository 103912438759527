import React from 'react';
import classNames from 'classnames';

import { Teams } from 'app/components/versus-ui';

import styles from './styles.less';

const PlayerName = ({ player }) => {
  const teamToClassName = {
    '-swat': Teams[player.team] === Teams.swat,
    '-suspects': Teams[player.team] === Teams.suspects,
  };
  return <div className={classNames(styles.name, teamToClassName)}>{player.name}</div>;
};

export default PlayerName;
