import React from 'react';
import classNames from 'classnames';

import VersusTeamPane from 'app/components/versus-ui/VersusTeamPane';
import VersusRulesPane from 'app/components/versus-ui/VersusRulesPane';
import VersusActivityPane from 'app/components/versus-ui/VersusActivityPane';
import { Teams } from 'app/components/versus-ui';

import styles from './styles.less';

const TeamPane = ({ team, server, ...props }) => {
  const status = server.status || {};
  // by default all players belong to the swat team
  const teamPlayers = (status.players || []).filter(
    (player) => (Teams[player.team] || Teams.swat) === team || (player.score === 0) === team,
  );
  return (
    <VersusTeamPane
      team={team}
      players={teamPlayers}
      scoreSwat={status.score_swat}
      scoreSus={status.score_sus}
      victSwat={status.vict_swat}
      victSus={status.vict_sus}
      {...props}
    />
  );
};

const ServerDetailVersus = (props) => {
  const { server } = props;
  return (
    <div className={styles.frame}>
      <div className={styles.briefing}>
        <VersusActivityPane extraClassName={classNames(styles.pane, styles.activity)} />
        <VersusRulesPane
          extraClassName={classNames(styles.pane, styles.rules)}
          title={server.status.mapname}
          rules={server.status.rules}
        />
      </div>
      <div className={styles.teams}>
        <TeamPane extraClassName={styles.pane} team={Teams.swat} {...props} />
        <TeamPane extraClassName={styles.pane} team={Teams.suspects} {...props} />
      </div>
    </div>
  );
};

export default ServerDetailVersus;
