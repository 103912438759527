import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import dayjs from 'app/dayjs';
import AppView from 'app/views/AppView';
import ContentBox from 'app/components/ContentBox';
import Logo from 'app/components/Logo';
import { SkeletonParagraph, SkeletonText, SkeletonTitle } from 'app/components/skeleton';
import { useGetArticlesQuery, endpoints, util as apiUtil } from 'app/api';
import { setPageMeta } from 'app/store/meta';

import styles from './styles.less';

const MainPageMenu = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.menu}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <ul>
        <li className={styles.divider}>{t('Player Chart')}</li>
        <li>
          <a className={styles.heading} href="/top/">
            {t('Top')}
          </a>
        </li>
        <li>
          <a className={styles.heading} href="/leaderboard/">
            {t('Leaderboard')}
          </a>
        </li>
        <li className={styles.divider}>{t('Multiplayer Game')}</li>
        <li>
          <Link className={styles.heading} to="/games/">
            {t('Games')}
          </Link>
        </li>
        <li>
          <Link className={styles.heading} to="/servers/">
            {t('Servers')}
          </Link>
        </li>
        <li className={styles.divider} />
        <li>
          <a className={styles.heading} href="/search/">
            {t('Search')}
          </a>
        </li>
        <li className={classNames(styles.divider, styles.finalDivider)} />
      </ul>
    </div>
  );
};

const Article = (props) => (
  <div className={styles.article}>
    <ContentBox title={props.title}>
      <div className={styles.text} dangerouslySetInnerHTML={{ __html: props.html }} />
      <footer>
        <div className={styles.signature}>{props.signature}</div>
        <div className={styles.date}>{dayjs.utc(props.date_published).fromNow()}</div>
      </footer>
    </ContentBox>
  </div>
);

const ArticleSkeleton = () => (
  <div className={styles.article}>
    <ContentBox>
      <div className={styles.skeletonTitle}>
        <SkeletonTitle extraClassName="w50" />
      </div>
      <div className={styles.text}>
        <SkeletonParagraph extraClassName="w100" />
      </div>
      <footer>
        <SkeletonText extraClassName="w25" />
      </footer>
    </ContentBox>
  </div>
);

const ArticleList = () => {
  const { data: articles, isLoading } = useGetArticlesQuery();
  return (
    <>
      {isLoading
        ? [...Array(5)].map((_, i) => <ArticleSkeleton key={i} />)
        : articles.map((article) => <Article key={article.id} {...article} />)}
    </>
  );
};

const MainPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setPageMeta()); // set default meta
  }, [dispatch]);

  return (
    <AppView>
      <div className={styles.container}>
        <MainPageMenu />
        <div className={styles.news}>
          <ArticleList />
        </div>
      </div>
    </AppView>
  );
};

export const prepareServerState = async (match, req, store) => {
  store.dispatch(endpoints.getArticles.initiate());
  store.dispatch(setPageMeta()); // set default meta for the main page
  await Promise.all(store.dispatch(apiUtil.getRunningQueriesThunk()));
};

export default MainPage;
