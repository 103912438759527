import React, { useCallback, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { FormDateField, FormSelectField } from 'app/components/form';
import styles from 'app/views/GameList/styles.less';
import Button from 'app/components/Button';
import { applyFilters, setFilters, clearFilters } from 'app/store/gameList';
import { useGetPopularMapsQuery, useGetPopularServersQuery } from 'app/api';

const defaultFilters = {
  gametype: '',
  map: '',
  server: '',
  year: '',
  month: '',
  day: '',
};

const GameFilterForm = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { filters } = useSelector((state) => state.gameList);
  const [formFilters, setFormFormFilters] = useState(filters || defaultFilters);
  const { data: popularMaps, isLoading: arePopularMapsLoading } = useGetPopularMapsQuery();
  const { data: popularServers, isLoading: arePopularServersLoading } = useGetPopularServersQuery();

  const mapOptions = [['', 'Any']];
  if (popularMaps) {
    popularMaps.forEach((mapObj) => {
      mapOptions.push([mapObj.id, mapObj.name]);
    });
  }
  const serverOptions = [['', 'Any']];
  if (popularServers) {
    popularServers.forEach((server) => {
      serverOptions.push([server.id, server.name_clean]);
    });
  }

  const appliedFiltersCount = useMemo(() => {
    return Object.values(formFilters).filter((value) => value !== '').length;
  }, [formFilters]);

  const handleInputChange = useCallback(
    (e) => {
      setFormFormFilters({
        ...formFilters,
        [e.target.name]: e.target.value,
      });
    },
    [formFilters],
  );

  const handleSubmit = useCallback(() => {
    dispatch(setFilters(formFilters));
    dispatch(applyFilters(location, navigate));
  }, [dispatch, formFilters, location, navigate]);

  const handleReset = useCallback(() => {
    setFormFormFilters(defaultFilters);
    dispatch(clearFilters(defaultFilters));
    dispatch(applyFilters(location, navigate));
  }, [dispatch, location, navigate]);

  return (
    <div>
      <FormSelectField
        label={t('Game Type')}
        onChange={handleInputChange}
        name="gametype"
        value={formFilters.gametype}
        options={[
          ['', t('Any')],
          ['Barricaded Suspects', t('Barricaded Suspects')],
          ['VIP Escort', t('VIP Escort')],
          ['Rapid Deployment', t('Rapid Deployment')],
          ['Smash And Grab', t('Smash And Grab')],
          ['CO-OP', t('CO-OP')],
        ]}
      />
      <FormSelectField
        label={t('Map')}
        onChange={handleInputChange}
        name="map"
        value={formFilters.map}
        options={mapOptions}
        disabled={arePopularMapsLoading}
      />
      <FormSelectField
        label={t('Server')}
        onChange={handleInputChange}
        name="server"
        value={formFilters.server}
        options={serverOptions}
        disabled={arePopularServersLoading}
      />
      <FormDateField
        yearName="year"
        yearValue={formFilters.year}
        monthName="month"
        monthValue={formFilters.month}
        dayName="day"
        dayValue={formFilters.day}
        onChange={handleInputChange}
      />
      <div className={styles.buttons}>
        <div className={styles.searchButton}>
          <Button
            title={t('Search Games')}
            onClick={handleSubmit}
            style="white"
            counter={appliedFiltersCount}
          />
        </div>
        <div className={styles.resetButton}>
          <Button title={t('Reset')} onClick={handleReset} style="white" />
        </div>
      </div>
    </div>
  );
};

export default GameFilterForm;
