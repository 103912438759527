import React from 'react';
import classNames from 'classnames';

import CountryFlag from 'app/components/CountryFlag';
import { SkeletonImage } from 'app/components/skeleton';

import styles from './styles.less';

export const PlayerPortraitSkeleton = ({ extraClassName }) => (
  <div className={styles.playerCard}>
    <div className={classNames(styles.picture, extraClassName)}>
      <SkeletonImage />
    </div>
  </div>
);

const PlayerPortrait = ({ player, extraClassName }) => {
  return (
    <div className={styles.playerCard}>
      {player.country && (
        <div className={styles.flag}>
          <CountryFlag iso={player.country} name={player.country_human} />
        </div>
      )}
      <div className={classNames(styles.picture, extraClassName)}>
        <img src={player.portrait_picture} />
      </div>
    </div>
  );
};

export default PlayerPortrait;
