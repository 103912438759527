import dayjs from 'app/dayjs';

class BaseModel {
  constructor(obj) {
    Object.assign(this, obj);
  }
}

export class Game extends BaseModel {
  getBaseLinkPathname() {
    const date = dayjs.utc(this.date_finished);
    // prettier-ignore
    return `/games/${date.format('YYYY')}/${date.format('MM')}/${date.format('DD')}/${this.map.slug}/${this.id}/`;
  }

  getLink() {
    return this.getBaseLinkPathname();
  }

  getHighlightsLink() {
    return `${this.getBaseLinkPathname()}highlights/`;
  }
}

export class Server extends BaseModel {
  getBaseLinkPathname() {
    return `/servers/${this.ip}:${this.port}/`;
  }

  getLink() {
    return this.getBaseLinkPathname();
  }

  getJoinLink() {
    return `${this.getBaseLinkPathname()}join/`;
  }

  getLeaderboardLink() {
    return `${this.getBaseLinkPathname()}leaderboard/`;
  }
}
