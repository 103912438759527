import React, { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { FormInputField } from 'app/components/form';
import YesNoButtonBar from 'app/components/YesNoButtonBar';
import { clearModal } from 'app/store/modal';

import styles from './styles.less';

const SearchBox = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState('');
  const { t } = useTranslation();

  const handleSubmit = useCallback(() => {
    const q = query.trim();
    if (q === '') {
      return;
    }
    dispatch(clearModal());
    window.location.href = `/search/?player=${encodeURIComponent(q)}`;
  }, [dispatch, query]);

  const handleCancel = useCallback(() => {
    dispatch(clearModal());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.input}>
        <FormInputField
          autoFocus
          onChange={(e) => setQuery(e.target.value)}
          placeholder={t('Type here to search')}
        />
      </div>
      <div className={styles.buttons}>
        <YesNoButtonBar onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
    </div>
  );
};

export default SearchBox;
