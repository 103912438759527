import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import YesNoButtonBar from 'app/components/YesNoButtonBar';
import { clearModal } from 'app/store/modal';
import { FormCheckboxField, FormCheckboxSelectField } from 'app/components/form';
import { setFilters } from 'app/store/serverList';

import styles from './styles.less';
import queryString from 'querystring';

const defaultFilters = {
  gamename: 'SWAT 4',
  gamever: '1.1',
  gametype: 'Barricaded Suspects',
  filter_gamename: false,
  filter_gamever: false,
  filter_gametype: false,
  hide_empty: false,
  hide_full: false,
  hide_passworded: false,
};

const FilterServers = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const filters = useSelector((state) => state.serverList.filters);
  const [formFilters, setFormFormFilters] = useState({ ...defaultFilters, ...filters });

  const handleInputChange = useCallback(
    (e) => {
      const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
      setFormFormFilters({
        ...formFilters,
        [e.target.name]: value,
      });
    },
    [formFilters],
  );

  const changeLocation = useCallback(
    (newFilters) => {
      const newLocation = { ...location };
      newLocation.search = `?${queryString.stringify(newFilters)}`;
      navigate(newLocation);
    },
    [location, navigate],
  );

  const handleSubmit = useCallback(() => {
    dispatch(setFilters(formFilters));
    changeLocation(formFilters);
    dispatch(clearModal());
  }, [changeLocation, dispatch, formFilters]);

  const handleCancel = useCallback(() => {
    dispatch(clearModal());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.fields}>
        <div className={styles.pane}>
          <FormCheckboxSelectField
            label={t('Game Mod')}
            name="gamename"
            value={formFilters.gamename}
            checkboxName="filter_gamename"
            checkboxValue={formFilters.filter_gamename}
            onChange={handleInputChange}
            options={[
              ['SWAT 4', t('SWAT 4')],
              ['SWAT 4X', t('The Stetchkov Syndicate')],
              ['SAS', t('SAS')],
              ['SEF', t('SEF')],
              ['SSF Realism Mod', t('SSF Realism Mod')],
            ]}
          />
          <FormCheckboxSelectField
            label={t('Game Version')}
            name="gamever"
            value={formFilters.gamever}
            checkboxName="filter_gamever"
            checkboxValue={formFilters.filter_gamever}
            onChange={handleInputChange}
            options={[
              ['1.0', '1.0'],
              ['1.1', '1.1'],
            ]}
          />
          <FormCheckboxSelectField
            label={t('Game Type')}
            name="gametype"
            value={formFilters.gametype}
            checkboxName="filter_gametype"
            checkboxValue={formFilters.filter_gametype}
            onChange={handleInputChange}
            options={[
              ['Barricaded Suspects', t('Barricaded Suspects')],
              ['VIP Escort', t('VIP Escort')],
              ['Rapid Deployment', t('Rapid Deployment')],
              ['Smash And Grab', t('Smash And Grab')],
              ['CO-OP', t('CO-OP')],
            ]}
          />
        </div>
        <div className={styles.pane}>
          <FormCheckboxField
            label={t('Hide Empty Servers')}
            name="hide_empty"
            value={formFilters.hide_empty}
            onChange={handleInputChange}
          />
          <FormCheckboxField
            label={t('Hide Full Servers')}
            name="hide_full"
            value={formFilters.hide_full}
            onChange={handleInputChange}
          />
          <FormCheckboxField
            label={t('Hide Password Protected')}
            name="hide_passworded"
            value={formFilters.hide_passworded}
            onChange={handleInputChange}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <YesNoButtonBar onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
    </div>
  );
};

export default FilterServers;
