import React from 'react';
import { SkeletonText } from 'app/components/skeleton';
import ContentBox from 'app/components/ContentBox';
import nl2br from 'app/utils/text/nl2br';

import styles from './styles.less';

const RulesSkeleton = () => (
  <div className={styles.skeleton}>
    <SkeletonText extraClassName="w75" />
    <SkeletonText extraClassName="w75" />
    <SkeletonText extraClassName="w50" />
  </div>
);

const VersusRulesPane = ({ title, rules, extraClassName }) => {
  return (
    <div className={extraClassName}>
      <ContentBox scrollable title={title}>
        {rules ? nl2br(rules) : <RulesSkeleton />}
      </ContentBox>
    </div>
  );
};

export default VersusRulesPane;
