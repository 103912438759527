import { createSlice } from '@reduxjs/toolkit';

import { normalizeBoolean } from 'app/utils/api/convert';

export const convertFiltersToQuery = (filters) => {
  if (!filters) {
    return {};
  }

  const queryParams = {};

  if (filters.filter_gamename) {
    queryParams.gamename = filters.gamename;
  }
  if (filters.filter_gamever) {
    queryParams.gamever = filters.gamever;
  }
  if (filters.filter_gametype) {
    queryParams.gametype = filters.gametype;
  }
  if (filters.hide_empty) {
    queryParams.empty = false;
  }
  if (filters.hide_full) {
    queryParams.full = false;
  }
  if (filters.hide_passworded) {
    queryParams.passworded = false;
  }

  return queryParams;
};

export const convertQueryToFilters = (query) => {
  const filters = { ...query };
  // normalize filter values (strings to booleans and so forth)
  for (const key in query) {
    filters[key] = normalizeBoolean(filters[key]);
  }
  return filters;
};

const initialState = {
  filters: {},
};

const slice = createSlice({
  name: 'serverList',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
  },
});

export const { setFilters } = slice.actions;

export default slice.reducer;
