import { createSlice } from '@reduxjs/toolkit';

const initialState = {};

const slice = createSlice({
  name: 'safeSettings',
  initialState,
  reducers: {
    configureSettings: () => {
      return {
        CSRF_COOKIE_NAME: process.env.SETTINGS_CSRF_COOKIE_NAME || 'csrftoken',
        CSRF_HEADER_NAME: process.env.SETTINGS_CSRF_HEADER_NAME || 'x-csrftoken',
        // prettier-ignore
        MOMENT_DATE_FORMAT: process.env.SETTINGS_MOMENT_DATE_FORMAT || 'MMMM D, YYYY',
        MOMENT_DATETIME_FORMAT:
          process.env.SETTINGS_MOMENT_DATETIME_FORMAT || 'MMMM D, YYYY - hh:mm',
        SENTRY_BROWSER_DSN: process.env.SETTINGS_SENTRY_BROWSER_DSN || null,
        API_NODE_BASE_URL: process.env.SETTINGS_API_NODE_BASE_URL || null,
        API_NEW_NODE_BASE_URL: process.env.SETTINGS_API_NEW_NODE_BASE_URL || null,
        API_NODE_HTTP_HOST: process.env.SETTINGS_API_NODE_HTTP_HOST || null,
        API_BROWSER_BASE_URL: process.env.SETTINGS_API_BROWSER_BASE_URL || null,
      };
    },
  },
});

export const { configureSettings } = slice.actions;

export default slice.reducer;
