import React from 'react';

import LoadingScreen from 'app/components/LoadingScreen';

import styles from './styles.less';

const LoadingScreenMock = () => {
  return (
    <div className={styles.container}>
      <LoadingScreen noTitle />
    </div>
  );
};

export default LoadingScreenMock;
