import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import dayjs from 'app/dayjs';
import ChapterView from 'app/views/ChapterView';
import { Game, Server } from 'app/models';
import Button from 'app/components/Button';
import LoadingScreen from 'app/components/LoadingScreen';
import RoundTimer from 'app/components/RoundTimer';
import Error500 from 'app/components/errors/Error500';
import { endpoints, useGetGameHighlightsQuery, useGetGameQuery, util as apiUtil } from 'app/api';

import styles from './styles.less';
import { preparePageMetaAction } from './meta';

const ActionBar = ({ gameId, game: gameObj }) => {
  const { t } = useTranslation();

  const game = new Game(gameObj);
  const server = new Server(game.server);

  const { data: highlights, isLoading: highlightsLoading } = useGetGameHighlightsQuery(gameId);
  const hasHighlights = !highlightsLoading && highlights?.length > 0;

  return (
    <div className={styles.buttons}>
      <div className={styles.buttonContainer}>
        <Button
          style="button"
          linkTo={game.getHighlightsLink()}
          title={t('Highlights')}
          disabled={!hasHighlights}
        />
      </div>
      <div className={styles.buttonContainer}>
        <Button style="button" linkTo={game.getLink()} title={t('Scores')} />
      </div>
      <div className={styles.buttonContainer}>
        <Button style="right" linkTo={server.getLink()} title={t('Server')} />
      </div>
    </div>
  );
};

const GameTime = ({ game }) => {
  const { t } = useTranslation();

  if (!game?.time) {
    return null;
  }

  // obtain gametype specific timer title
  const isCoopGame = ['co-op', 'co-op-qmm'].includes(game?.gametype_slug);
  const timerTitle = isCoopGame ? t('Mission Time') : t('Round Time');

  return (
    <div className={styles.roundTimer}>
      <RoundTimer title={timerTitle} seconds={game.time} />
    </div>
  );
};

const GameDetail = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const settings = useSelector((state) => state.safeSettings);
  const {
    data: game,
    error,
    isLoading,
  } = useGetGameQuery(params.gameId, {
    refetchOnMountOrArgChange: true, // refetch because the cached state may contain partial data
  });

  useEffect(() => {
    dispatch(preparePageMetaAction(game, settings));
  }, [game, settings, dispatch]);

  if (error) {
    return <Error500 />;
  }

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <ChapterView
      backgroundImage={game.map.background_picture}
      navBar={<Button style="exit" linkTo="/games/" title={t('Quit')} />}
      headerMiddle={game.server.name_clean}
      footerLeftLower={dayjs.utc(game.date_finished).format(settings.MOMENT_DATETIME_FORMAT)}
      footerMiddle={<GameTime game={game} />}
      footerRightUpper={<ActionBar gameId={params.gameId} game={game} />}
    >
      <Outlet />
    </ChapterView>
  );
};

export const prepareServerState = async (match, req, store) => {
  const { gameId } = match.params;
  // dispatch the api queries
  store.dispatch(endpoints.getGame.initiate(gameId));
  store.dispatch(endpoints.getGameHighlights.initiate(gameId));
  await Promise.all(store.dispatch(apiUtil.getRunningQueriesThunk()));
  // get the data from the store to configure the page meta
  const state = store.getState();
  const result = endpoints.getGame.select(gameId)(state);
  const { data: game } = result;
  store.dispatch(preparePageMetaAction(game, state.safeSettings));
};

export default GameDetail;
