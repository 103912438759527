import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import dayjs from 'app/dayjs';
import { Game } from 'app/models';

import styles from './styles.less';

export const GameCardSkeleton = () => (
  <div className={styles.card}>
    <div className={classNames(styles.cardFrame, styles.skeleton)}>
      <div className={styles.skeletonPicture}>
        <div className={styles.upperInfo}>
          <div className={styles.dateFinished} />
        </div>
        <div className={styles.lowerInfo}>
          <div className={styles.score}></div>
          <div className={styles.gametype} />
        </div>
      </div>
    </div>
  </div>
);

const GameCard = ({ game: gameObj }) => {
  const game = new Game(gameObj);
  const { map } = game;

  const score = ['co-op', 'co-op-qmm'].includes(game?.gametype_slug)
    ? `${game.coop_score}/100`
    : `${game.score_swat}:${game.score_sus}`;
  const dateFinished = dayjs.utc(game.date_finished);

  return (
    <Link to={game.getLink()} className={styles.card}>
      <div className={styles.cardFrame}>
        <div
          className={styles.picture}
          style={{ backgroundImage: `url('${map.preview_picture}')` }}
        >
          <div className={styles.upperInfo}>
            <div className={styles.dateFinished} title={dateFinished.format('LLL')}>
              {dateFinished.fromNow()}
            </div>
          </div>
          <div className={styles.lowerInfo}>
            <div className={styles.score}>{score}</div>
            <div className={styles.gametype}>{game.gametype_short}</div>
          </div>
        </div>
      </div>
    </Link>
  );
};

GameCard.propTypes = {
  game: PropTypes.object.isRequired,
};

export default GameCard;
