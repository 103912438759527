import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Button from 'app/components/Button';

import styles from 'app/components/Error/styles.less';

const Error = (props) => {
  const { t } = useTranslation();
  const { message, url } = props;
  return (
    <div className={styles.container}>
      <div className={styles.error}>
        <div className={styles.message}>{message}</div>
        <div className={styles.button}>
          <Button style="white" href={url} title={t('OK')} />
        </div>
      </div>
    </div>
  );
};

Error.propTypes = {
  message: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

export default Error;
