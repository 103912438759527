import { createSlice } from '@reduxjs/toolkit';

export const ModalTypes = {
  NONE: 'NONE',
  SEARCH: 'SEARCH',
  ADD_SERVER: 'ADD_SERVER',
  FILTER_SERVERS: 'FILTER_SERVERS',
};

const initialState = {
  modalType: ModalTypes.NONE,
};

const slice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modalType = action.payload;
    },
    clearModal: (state) => {
      state.modalType = ModalTypes.NONE;
    },
  },
});

export const { setModal, clearModal } = slice.actions;

export default slice.reducer;
