import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import VersusTeamPane from 'app/components/versus-ui/VersusTeamPane';
import VersusRulesPane from 'app/components/versus-ui/VersusRulesPane';
import VersusActivityPane from 'app/components/versus-ui/VersusActivityPane';
import { Teams } from 'app/components/versus-ui';

import styles from './styles.less';

const outcomeTextTrans = {
  swat_bs: 'SWAT has won the round!',
  sus_bs: 'The Suspects have won the round!',
  swat_rd: 'SWAT has disarmed all the bombs!',
  sus_rd: 'The Suspects have won the round!',
  tie: 'The round has ended in a tie!',
  swat_vip_escape: 'The VIP has escaped!',
  sus_vip_good_kill: 'The Suspects have killed the VIP!',
  swat_vip_bad_kill: 'The Suspects have killed the VIP too soon!',
  sus_vip_bad_kill: 'SWAT has accidentally killed the VIP!',
  swat_sg: 'SWAT has defended the case!',
  sus_sg: 'The suspects have escaped with the case!',
};

const TeamPane = ({ team, game, ...props }) => {
  const teamPlayers = (game.players || []).filter((player) => Teams[player.team] === team);
  return (
    <VersusTeamPane
      team={team}
      players={teamPlayers}
      scoreSwat={game.score_swat}
      scoreSus={game.score_sus}
      victSwat={game.vict_swat}
      victSus={game.vict_sus}
      {...props}
    />
  );
};

const GameDetailVersus = (props) => {
  const { game } = props;
  const { t } = useTranslation();

  const outcomeText = outcomeTextTrans[game.outcome];
  const outcome = outcomeText ? t(outcomeText) : null;

  return (
    <div className={styles.frame}>
      <div className={styles.briefing}>
        <VersusActivityPane extraClassName={classNames(styles.pane, styles.activity)} />
        <VersusRulesPane title={game.map.name} rules={game.rules} extraClassName={styles.pane} />
      </div>
      <div className={styles.outcome}>
        <div className={styles.text}>{outcome}</div>
      </div>
      <div className={styles.teams}>
        <TeamPane team={Teams.swat} extraClassName={styles.pane} {...props} />
        <TeamPane team={Teams.suspects} extraClassName={styles.pane} {...props} />
      </div>
    </div>
  );
};

export default GameDetailVersus;
