import React from 'react';
import { useSelector } from 'react-redux';

import SearchBox from 'app/modals/SearchBox';
import FilterServers from 'app/modals/FilterServers';
import AddServer from 'app/modals/AddServer';
import { ModalTypes } from 'app/store/modal';

import styles from './styles.less';

const modalMapping = {
  [ModalTypes.SEARCH]: SearchBox,
  [ModalTypes.ADD_SERVER]: AddServer,
  [ModalTypes.FILTER_SERVERS]: FilterServers,
};

const ModalManager = ({ children }) => {
  const modalType = useSelector((state) => state.modal.modalType);
  const ModalComponent = modalMapping[modalType];

  if (ModalComponent === undefined) {
    return children;
  }

  return (
    <>
      <div className={styles.modalOverlay}>
        <div className={styles.modal}>
          <ModalComponent />
        </div>
      </div>
      {children}
    </>
  );
};

export default ModalManager;
