import { createSlice } from '@reduxjs/toolkit';

import { endpoints } from 'app/api';

export const initTicker = () => {
  return (dispatch) => {
    const tickerId = setInterval(() => dispatch(countRemainingTime()), 1000);
    dispatch(setTicker(tickerId));
  };
};

export const countRemainingTime = () => {
  return (dispatch, getState) => {
    const { remainingTime: currentRemainingTime } = getState().serverDetail;
    let effectiveRemainingTime = currentRemainingTime;
    // drop the timer on round reset or when the server is empty
    if (!effectiveRemainingTime || effectiveRemainingTime <= 0) {
      effectiveRemainingTime = null;
    }
    // deduct one second locally
    else {
      effectiveRemainingTime -= 1;
    }
    dispatch(setRemainingTime(effectiveRemainingTime));
  };
};

const initialState = {
  remainingTime: null,
  timeTickerId: null,
};

const slice = createSlice({
  name: 'serverDetail',
  initialState,
  reducers: {
    setTicker: (state, action) => {
      state.timeTickerId = action.payload;
    },
    setRemainingTime: (state, action) => {
      state.remainingTime = action.payload;
    },
    stopTicker: (state, action) => {
      const { timeTickerId } = state;
      clearInterval(timeTickerId);
      state.timeTickerId = null;
      state.remainingTime = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(endpoints.getServer.matchFulfilled, (state, { payload: server }) => {
      // server has crashed? freeze the timer
      if (!server.status) {
        return;
      }
      const hasPlayers = !!server.status?.players?.length;
      // don't count time when server is empty
      if (!(server.status.time_round && hasPlayers) || server.status.time_round < 0) {
        state.remainingTime = null;
        return;
      }
      const actualRemainingTime = server.status.time_round;
      const localRemainingTime = state.remainingTime;
      // because game servers are not refreshed on the backend side
      // as often as we fetch details for them
      // local and server time often go out of sync,
      // don't correct the timer when the difference is not significant
      const diff = Math.abs(actualRemainingTime - localRemainingTime);
      if (typeof actualRemainingTime !== typeof localRemainingTime || diff > 10) {
        state.remainingTime = actualRemainingTime;
      }
    });
  },
});

export const { setRemainingTime, setTicker, stopTicker } = slice.actions;

export default slice.reducer;
