import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

import dayjs from 'app/dayjs';
import ChapterTitle from 'app/components/ChapterTitle';
import PlayerPortrait, { PlayerPortraitSkeleton } from 'app/components/PlayerPortrait';
import PlayerName from 'app/components/PlayerName';
import ContentBox from 'app/components/ContentBox';
import { SkeletonText, SkeletonTitle } from 'app/components/skeleton';

import { useGetGameQuery, useGetGameHighlightsQuery } from 'app/api';

import styles from './styles.less';

const GameSummary = ({ game, isGameLoading }) => {
  const settings = useSelector((state) => state.safeSettings);

  if (isGameLoading) {
    return <SkeletonText extraClassName="w100" />;
  }

  return (
    <>
      <div>{game.map.name}</div>
      <div className={styles.comma}>,&nbsp;</div>
      <div>{dayjs.utc(game.date_finished).format(settings.MOMENT_DATETIME_FORMAT)}</div>
    </>
  );
};

const HighlightCard = ({ player, title, description }) => (
  <div className={styles.highlightContent}>
    <div className={styles.highlight}>
      <ContentBox title={title}>
        <div className={styles.card}>
          <PlayerPortrait player={player} />
          <div className={styles.detail}>
            <PlayerName player={player} />
            <div className={styles.description}>{description}</div>
          </div>
        </div>
      </ContentBox>
    </div>
  </div>
);

const HighlightCardSkeleton = () => (
  <div className={styles.highlightContent}>
    <div className={styles.highlight}>
      <ContentBox>
        <div className={styles.skeletonTitle}>
          <SkeletonTitle extraClassName="w50" />
        </div>
        <div className={styles.card}>
          <PlayerPortraitSkeleton />
          <div className={styles.detail}>
            <SkeletonText extraClassName="w33" />
            <div className={styles.description}>
              <SkeletonText extraClassName="w75" />
            </div>
          </div>
        </div>
      </ContentBox>
    </div>
  </div>
);

const Highlights = ({ gameId }) => {
  const { t } = useTranslation();
  const { data: highlights, isLoading } = useGetGameHighlightsQuery(gameId);

  if (isLoading) {
    return <HighlightCardSkeleton />;
  }

  if (highlights && highlights.length === 0) {
    return <div>{t('No highlights available for this game.')}</div>;
  }

  return highlights.map((item) => <HighlightCard key={item.title} {...item} />);
};

const GameDetailHighlights = () => {
  const { t } = useTranslation();

  const { gameId } = useParams();
  const { data: game, isLoading: isGameLoading } = useGetGameQuery(gameId);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <ChapterTitle title={t('Highlights')} />
      </div>
      <div className={styles.summary}>
        <GameSummary game={game} isGameLoading={isGameLoading} />
      </div>
      <div className={styles.content}>
        <Highlights gameId={gameId} />
      </div>
    </div>
  );
};

export default GameDetailHighlights;
