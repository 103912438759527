import React from 'react';

import ContentBox from 'app/components/ContentBox';

const VersusActivityPane = ({ extraClassName }) => (
  <div className={extraClassName}>
    <ContentBox />
  </div>
);

export default VersusActivityPane;
