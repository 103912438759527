import React from 'react';
import { useTranslation } from 'react-i18next';

import Error from 'app/components/Error';

const Error404 = () => {
  const { t } = useTranslation();
  return <Error message={t('The requested page does not exist')} url="/" />;
};

export default Error404;
