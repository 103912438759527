import React from 'react';
import MainPage, { prepareServerState as prepareMainPageServerState } from 'app/views/MainPage';
import GameList, { prepareServerState as prepareGameListServerState } from 'app/views/GameList';
import GameDetail, {
  prepareServerState as prepareGameDetailServerState,
} from 'app/views/GameDetail';
import GameDetailScore from 'app/views/GameDetail/GameDetailScore';
import GameDetailHighlights from 'app/views/GameDetail/GameDetailHighlights';
import ServerList, {
  prepareServerState as prepareServerListServerState,
} from 'app/views/ServerList';
import ServerDetail, {
  prepareServerState as prepareServerDetailServerState,
} from 'app/views/ServerDetail';
import ServerDetailScore from 'app/views/ServerDetail/ServerDetailScore';
import ServerDetailJoin from 'app/views/ServerDetail/ServerDetailJoin';
import ServerDetailLeaderboard from 'app/views/ServerDetail/ServerDetailLeaderboard';
import Search, { prepareServerState as prepareSearchServerState } from 'app/views/Search';
import Error404 from 'app/components/errors/Error404';

export default () => [
  {
    path: '/',
    element: <MainPage />,
    init: prepareMainPageServerState,
  },
  {
    path: '/search/',
    element: <Search />,
    init: prepareSearchServerState,
  },
  {
    path: '/games/',
    element: <GameList />,
    init: prepareGameListServerState,
  },
  {
    path: '/games/:yearSlug/:monthSlug/:daySlug/:mapSlug/:gameId/',
    element: <GameDetail />,
    children: [
      {
        index: true,
        element: <GameDetailScore />,
      },
      {
        path: 'highlights/',
        element: <GameDetailHighlights />,
      },
    ],
    init: prepareGameDetailServerState,
  },
  {
    path: '/servers/',
    element: <ServerList />,
    init: prepareServerListServerState,
  },
  {
    path: '/servers/:address/',
    element: <ServerDetail />,
    children: [
      {
        index: true,
        element: <ServerDetailScore />,
      },
      {
        path: 'join/',
        element: <ServerDetailJoin />,
      },
      {
        path: 'leaderboard/',
        element: <ServerDetailLeaderboard />,
      },
    ],
    init: prepareServerDetailServerState,
  },
  {
    path: '*',
    element: <Error404 />,
  },
];
