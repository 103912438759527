import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Teams } from 'app/components/versus-ui';
import CountryFlag from 'app/components/CountryFlag';

import styles from './styles.less';

const TeamTableHeader = ({ team }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.teamTableHeader}>
      <div className={styles.playerName}>{team === Teams.swat ? t('Officer') : t('Suspect')}</div>
      <div className={styles.playerScore} />
      <div className={styles.playerArrests} />
      <div className={styles.playerArrested} />
      <div className={styles.playerKills} />
      <div className={styles.playerDeaths} />
      <div className={styles.playerObj} />
      <div className={styles.playerPing} />
    </div>
  );
};

const EmptyTeamRow = () => (
  <div className={styles.teamRowEmpty}>
    <div className={styles.playerName} />
    <div className={styles.playerScore} />
    <div className={styles.playerArrests} />
    <div className={styles.playerArrested} />
    <div className={styles.playerKills} />
    <div className={styles.playerDeaths} />
    <div className={styles.playerObj} />
    <div className={styles.playerPing} />
  </div>
);

const PingOrCountry = ({ ping, country, country_human: countryHuman }) => {
  if (country) {
    return <CountryFlag iso={country} name={countryHuman} />;
  }
  return ping || 0;
};

const PlayerTable = ({ team, players }) => {
  const sortPlayersByScore = (playerA, playerB) => {
    return playerB.score - playerA.score;
  };
  return (
    <div className={styles.teamTable}>
      <TeamTableHeader team={team} />
      {players.sort(sortPlayersByScore).map((player) => {
        const playerName = player.vip ? `${player.name} (VIP)` : player.name;
        return (
          <div key={player.id} className={styles.teamRow}>
            <div className={classNames(styles.playerName, { '-vip': player.vip })}>
              {playerName}
            </div>
            <div className={styles.playerScore}>{player.score || 0}</div>
            <div className={styles.playerArrests}>{player.arrests || 0}</div>
            <div className={styles.playerArrested}>{player.arrested || 0}</div>
            <div className={styles.playerKills}>{player.kills || 0}</div>
            <div className={styles.playerDeaths}>{player.deaths || 0}</div>
            <div className={styles.playerObj}>{player.special || 0}</div>
            <div className={styles.playerPing}>
              <PingOrCountry {...player} />
            </div>
          </div>
        );
      })}
      <EmptyTeamRow />
    </div>
  );
};

const VersusTeamPane = ({
  team,
  players,
  scoreSwat,
  scoreSus,
  victSwat,
  victSus,
  extraClassName,
}) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.team, extraClassName)}>
      <>
        {team === Teams.swat ? (
          <div className={styles.teamStats}>
            <div className={styles.teamName}>
              {t('SWAT')}: {scoreSwat || 0}
            </div>
            <div className={styles.teamVictories}>
              {t('Rounds won')}: {victSwat || 0}
            </div>
          </div>
        ) : (
          <div className={styles.teamStats}>
            <div className={styles.teamName}>
              {t('Suspects')}: {scoreSus || 0}
            </div>
            <div className={styles.teamVictories}>
              {t('Rounds won')}: {victSus || 0}
            </div>
          </div>
        )}
        <PlayerTable team={team} players={players} />
      </>
    </div>
  );
};

export default VersusTeamPane;
