/* global globals */
import Cookies from 'js-cookie';

const prepareRequestParams = (url, settings) => {
  const {
    API_NODE_BASE_URL: apiNodeBaseURL,
    API_NODE_HTTP_HOST: apiNodeHttpHost,
    API_BROWSER_BASE_URL: apiBrowserBaseUrl,
    CSRF_COOKIE_NAME: csrfCookieName,
    CSRF_HEADER_NAME: csrfHeaderName,
  } = settings;

  const baseUrl = globals.IS_SERVER ? apiNodeBaseURL : apiBrowserBaseUrl;
  const requestUrl = baseUrl ? `${baseUrl}${url}` : url;

  const headers = {
    'content-type': 'application/json; charset=utf-8',
    accept: 'application/json',
  };

  if (!globals.IS_SERVER) {
    const csrfCookie = Cookies.get(csrfCookieName);
    if (csrfCookie) {
      headers[csrfHeaderName] = csrfCookie;
    }
  } else {
    headers['x-forwarded-host'] = apiNodeHttpHost;
  }

  const fetchParams = {
    method: 'get',
    credentials: 'same-origin',
    headers,
  };

  return [requestUrl, fetchParams];
};

function json(resp) {
  return new Promise((resolve) =>
    resp.text().then((text) => {
      let json;
      try {
        json = text ? JSON.parse(text) : {};
      } catch (error) {
        json = {};
      }
      resolve({
        status: resp.status,
        headers: resp.headers,
        ok: resp.ok,
        json,
      });
    }),
  );
}

function request(url, settings) {
  const [requestUrl, fetchParams] = prepareRequestParams(url, settings);
  return new Promise((resolve, reject) => {
    return fetch(requestUrl, fetchParams)
      .then(json)
      .then((resp) => {
        const result = {
          data: resp.json,
          resp,
        };
        if (resp.ok) {
          return resolve(result);
        } else {
          if (globals.IS_SERVER) {
            console.log(`failed to request ${requestUrl}; status ${resp.status}`);
          }
          return reject(result);
        }
      })
      .catch((error) => {
        if (globals.IS_SERVER) {
          console.log(`got an error requesting ${requestUrl}: ${error}`);
        }
        reject(error);
      });
  });
}

export default request;
