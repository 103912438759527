import React from 'react';
import ContentBox from 'app/components/ContentBox';

import styles from './styles.less';

const CoopPaneBox = ({ title, children }) => (
  <ContentBox>
    <div className={styles.paneTitle}>{title}</div>
    <div className={styles.paneContent}>{children}</div>
  </ContentBox>
);

export default CoopPaneBox;
