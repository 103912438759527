import React from 'react';
import styles from './styles.less';

const secondsToTime = (seconds) => {
  const units = [];
  seconds = parseInt(seconds);

  [3600, 60, 1].forEach((unitSize) => {
    const unit = Math.floor(seconds / unitSize);
    if (unit > 0) {
      units.push(unit);
      seconds -= unit * unitSize;
    } else if (units.length > 0) {
      units.push(0);
    }
  });

  // ensure there is at least a minute
  while (units.length < 2) {
    units.unshift(0);
  }

  // ensure everything but the first unit is a 2 digit value
  for (let i = 1; i < units.length; i++) {
    if (units[i] < 10) {
      units[i] = '0' + units[i];
    }
  }

  return units.join(':');
};

const RoundTimer = ({ title, seconds }) => (
  <div className={styles.timer}>
    <div className={styles.title}>{title}</div>
    <div className={styles.value}>{secondsToTime(seconds)}</div>
  </div>
);

export default RoundTimer;
