import React from 'react';
import { useParams } from 'react-router-dom';

import { useGetServerQuery } from 'app/api';

import ServerNoStatus from './ServerNoStatus';
import ServerDetailCoop from './ServerDetailCoop';
import ServerDetailVersus from './ServerDetailVersus';

const ServerDetailScore = () => {
  const { address } = useParams();
  const { data: server, isLoading } = useGetServerQuery(address);

  if (isLoading) {
    return null;
  }

  if (!server.status) {
    return <ServerNoStatus server={server} />;
  }

  if (server.status.gametype_slug === 'co-op') {
    return <ServerDetailCoop server={server} />;
  } else {
    return <ServerDetailVersus server={server} />;
  }
};

export default ServerDetailScore;
