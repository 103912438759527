import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { FormInputField } from 'app/components/form';
import Button from 'app/components/Button';

import styles from './styles.less';

export const BrowserMockState = {
  INITIAL: 0,
  DIRECT_FORM_OPEN: 1,
  DIRECT_FORM_ENTER: 2,
  DIRECT_FORM_SUBMIT: 3,
};

const ServerDirectFormMock = ({ state, address }) => {
  const { t } = useTranslation();

  const showServerAddress = state >= BrowserMockState.DIRECT_FORM_ENTER;
  const submitButtonActive = state === BrowserMockState.DIRECT_FORM_SUBMIT;

  return (
    <div className={styles.directFormOverlay}>
      <div className={styles.directFormContainer}>
        <div className={styles.directForm}>
          <div className={styles.fields}>
            <div className={styles.field}>
              <FormInputField
                label={t('Please enter the IP address:')}
                value={showServerAddress ? address || '' : null}
              />
            </div>
            <div className={styles.field}>
              <FormInputField label={t('Password (Optional):')} />
            </div>
          </div>
          <div className={styles.buttons}>
            <div className={styles.button}>
              <Button style="submit" className={cn({ active: submitButtonActive })} />
            </div>
            <div className={styles.button}>
              <Button style="cancel" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ServerListMock = ({ state, address }) => {
  return (
    <div className={styles.serverListContainer}>
      <div className={styles.serverTable}>
        <div className={styles.serverTableHeader}>
          <div className={styles.serverPassworded}>&nbsp;</div>
          <div className={styles.serverHostname} />
          <div className={styles.serverPlayers} />
          <div className={styles.serverGametype} />
          <div className={styles.serverMap} />
          <div className={styles.serverAddress} />
          <div className={styles.serverGame} />
          <div className={styles.serverVer} />
        </div>
        <div className={styles.serverTableBody}>
          <div className={styles.serverPassworded}></div>
          <div className={styles.serverHostname}></div>
          <div className={styles.serverPlayers}></div>
          <div className={styles.serverGametype}></div>
          <div className={styles.serverMap}></div>
          <div className={styles.serverAddress}></div>
          <div className={styles.serverGame}></div>
          <div className={styles.serverVer}></div>
        </div>
      </div>
      {state >= BrowserMockState.DIRECT_FORM_OPEN ? (
        <ServerDirectFormMock state={state} address={address} />
      ) : null}
    </div>
  );
};

const ButtonBarMock = ({ state }) => {
  const { t } = useTranslation();
  const directButtonActive = state >= BrowserMockState.DIRECT_FORM_OPEN;
  return (
    <div className={styles.actionBar}>
      <Button title={t('Update')} style="white" className={styles.button} />
      <Button title={t('Refresh')} style="white" className={styles.button} />
      <Button
        title={t('Direct IP')}
        style="white"
        className={cn(styles.button, { active: directButtonActive })}
      />
      <Button title={t('Filters')} style="white" className={styles.button} />
    </div>
  );
};

const ServerBrowserMock = ({ state, address }) => {
  return (
    <div className={styles.container}>
      <ServerListMock state={state} address={address} />
      <ButtonBarMock state={state} />
    </div>
  );
};

export default ServerBrowserMock;
