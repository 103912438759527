import React from 'react';
import { useTranslation } from 'react-i18next';

import Error from 'app/components/Error';

const Error500 = () => {
  const { t } = useTranslation();
  return <Error message={t('Internal error. Please try again later')} url="/" />;
};

export default Error500;
