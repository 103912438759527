import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CoopPaneBox from 'app/components/coop-ui/CoopPaneBox';
import { SkeletonText } from 'app/components/skeleton';

import styles from './styles.less';

const CoopObjectiveRow = (obj) => {
  const statusToClass = {
    'in-progress': styles.inProgress,
    completed: styles.completed,
    failed: styles.failed,
  };
  return (
    <div className={styles.objective}>
      <div className={styles.title}>{obj.name}</div>
      <div className={classNames(styles.status, statusToClass[obj.status_slug])}>{obj.status}</div>
    </div>
  );
};

const CoopObjectiveRowSkeleton = () => {
  return (
    <div className={styles.objective}>
      <div className={styles.title}>
        <div className={styles.skeleton}>
          <SkeletonText extraClassName="w75" />
        </div>
      </div>
      <div className={styles.status}>
        <div className={styles.skeleton}>
          <SkeletonText extraClassName="w50" />
        </div>
      </div>
    </div>
  );
};

const ObjectiveList = ({ objectives }) => {
  if (!objectives) {
    return [...Array(2)].map((_, i) => <CoopObjectiveRowSkeleton key={i} />);
  }

  return objectives.map((item) => <CoopObjectiveRow key={item.name} {...item} />);
};

const CoopObjectivesPane = ({ objectives, extraClassName }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.objectives, extraClassName)}>
      <CoopPaneBox title={t('Objectives')}>
        <ObjectiveList objectives={objectives} />
      </CoopPaneBox>
    </div>
  );
};

export default CoopObjectivesPane;
