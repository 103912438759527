import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import queryString from 'querystring';

import request from 'app/utils/request';
import { parseQuery } from 'app/utils/url';
import { util as apiUtil } from 'app/api';

export const prepareBrowserState = () => {
  return async (dispatch, getState) => {
    const { gameList: state } = getState();
    if (!state.games) {
      await dispatch(fetchGames());
    }
  };
};

export const fetchGames = createAsyncThunk('gameList/fetchGames', async (_, thunkAPI) => {
  const { gameList, safeSettings: settings } = thunkAPI.getState();
  const { filters, nextPageCursor } = gameList;

  // build next request url
  let qs = {};
  if (filters) {
    qs = { ...qs, ...filters };
  }
  // remove empty string values
  qs = Object.fromEntries(Object.entries(qs).filter(([_, value]) => value !== ''));

  if (nextPageCursor) {
    qs = { cursor: nextPageCursor, ...qs };
  }
  qs.limit = 30;
  const url = `/api/games/?${queryString.stringify(qs)}`;

  return request(url, settings).then((res) => {
    // prefill api cache with partial game states
    for (const game of res.data.results) {
      thunkAPI.dispatch(apiUtil.upsertQueryData('getGame', `${game.id}`, game));
    }
    return [res.data.results, res.data.next];
  });
});

export const applyFilters = (location, navigate) => {
  return async (dispatch, getState) => {
    const { filters } = getState().gameList;
    dispatch(fetchGames());
    // update querystring, so it can be copied and pasted
    // the next time the component is mounted the filter params from the querystring
    // will be applied as initial data
    const newLocation = { ...location };
    newLocation.search = `?${queryString.stringify(filters)}`;
    navigate(newLocation);
  };
};

const initialState = {
  games: null,
  filters: null,
  nextPageCursor: null,
  isPageLoading: true,
};

const slice = createSlice({
  name: 'gameList',
  initialState,
  reducers: {
    setFilters: (state, action) => {
      state.filters = action.payload;
      state.nextPageCursor = null;
      state.games = [];
    },
    clearFilters: (state, action) => {
      state.filters = null;
      state.nextPageCursor = null;
      state.games = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchGames.pending, (state) => {
        state.isPageLoading = true;
      })
      .addCase(fetchGames.fulfilled, (state, action) => {
        const [games, nextPageQuery] = action.payload;
        state.isPageLoading = false;
        state.games = state.games ? state.games.concat(games) : games;
        state.nextPageCursor = nextPageQuery ? parseQuery(nextPageQuery).cursor : null;
      });
  },
});

export const { setFilters, clearFilters } = slice.actions;

export default slice.reducer;
