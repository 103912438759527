import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './styles.less';

const ServerNoStatus = ({ server }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.container}>
      <div className={styles.contentBox}>{t('The server appears to be offline...')}</div>
    </div>
  );
};

export default ServerNoStatus;
