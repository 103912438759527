import React, { useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { parseQuery } from 'app/utils/url';
import ChapterView from 'app/views/ChapterView';
import ContentBox from 'app/components/ContentBox';
import Button from 'app/components/Button';
import GameCard, { GameCardSkeleton } from 'app/components/GameCard';
import { setFilters, fetchGames, prepareBrowserState } from 'app/store/gameList';
import { endpoints, util as apiUtil } from 'app/api';
import { setPageMeta } from 'app/store/meta';

import GameFilterForm from './GameFilterForm';
import styles from './styles.less';

const GameCards = ({ items }) => {
  return items.map((game) => (
    <div key={game.id} className={styles.item}>
      <GameCard game={game} />
    </div>
  ));
};

const GameCardsSkeleton = () => {
  return [...Array(10)].map((_, i) => (
    <div key={i} className={styles.item}>
      <GameCardSkeleton />
    </div>
  ));
};

const NoGames = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.noGames}>
      <div className={styles.text}>{t('No games found')}</div>
    </div>
  );
};

const LoadMoreButton = ({ onMore }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.loadMore}>
      <div className={styles.button}>
        <Button title={t('Load More')} style="white" onClick={onMore} />
      </div>
    </div>
  );
};

const GameList = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const state = useSelector((state) => state.gameList);
  const { games, isPageLoading, nextPageCursor } = state;

  useEffect(() => {
    dispatch(prepareBrowserState());
    dispatch(preparePageMetaAction());
  }, [dispatch]);

  const handleMore = useCallback(() => {
    dispatch(fetchGames());
  }, [dispatch]);

  const renderGameCards = () => {
    if (games) {
      return <GameCards items={games} />;
    }
    return null;
  };

  const renderGameCardsSkeleton = () => {
    if (isPageLoading) {
      return <GameCardsSkeleton />;
    }
    return null;
  };

  const renderNoGames = () => {
    if (!isPageLoading && games && games.length === 0) {
      return <NoGames />;
    }
    return null;
  };

  const renderLoadMoreButton = () => {
    if (nextPageCursor && !isPageLoading) {
      return <LoadMoreButton onMore={handleMore} />;
    }
    return null;
  };

  return (
    <ChapterView title={t('Games')}>
      <div className={styles.container}>
        <div className={styles.gameListContainer}>
          <ContentBox>
            <div className={styles.list}>
              {renderGameCards()}
              {renderGameCardsSkeleton()}
              {renderNoGames()}
              {renderLoadMoreButton()}
            </div>
          </ContentBox>
        </div>
        <div className={styles.searchMenuContainer}>
          <div className={styles.searchMenu}>
            <ContentBox title={t('Search Games')}>
              <GameFilterForm />
            </ContentBox>
          </div>
        </div>
      </div>
    </ChapterView>
  );
};

const preparePageMetaAction = () => {
  return setPageMeta({
    title: 'Game Archive · Debriefing Archive for Completed Missions',
    description:
      'The SWAT 4 Stats Game Archive functions as a debriefing archive ' +
      'for completed missions. ' +
      'Review past engagements, assess tactics, and identify patterns ' +
      'to refine your strategies and enhance performance of your team.',
  });
};

export const prepareServerState = async (match, req, store) => {
  const queryFilters = parseQuery(req.url);
  store.dispatch(setFilters(queryFilters));
  store.dispatch(endpoints.getPopularMaps.initiate());
  store.dispatch(endpoints.getPopularServers.initiate());

  const runningQueries = store.dispatch(apiUtil.getRunningQueriesThunk());
  const promises = [...runningQueries];
  promises.push(store.dispatch(fetchGames()));
  await Promise.all(promises);

  store.dispatch(preparePageMetaAction());
};

export default GameList;
