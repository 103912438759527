/* global globals */
import Cookies from 'js-cookie';
import { fetchBaseQuery, createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'querystring';

import { convertFiltersToQuery } from 'app/store/serverList';

const getBaseUrl = ({ url, browserBaseUrl, nodeBaseUrl, newNodeBaseUrl }) => {
  if (!globals.IS_SERVER) {
    return browserBaseUrl;
  }
  const [path] = url.split('?');
  // for /api/articles/, /api/games/, etc urls we should use the new service
  switch (path) {
    case '/api/articles/':
    case '/api/games/':
    case '/api/maps/':
      return newNodeBaseUrl;
    default:
      return nodeBaseUrl;
  }
};

const query = (args, api, extraOptions) => {
  const settings = api.getState().safeSettings;
  const baseUrl = getBaseUrl({
    url: typeof args === 'string' ? args : args.url,
    browserBaseUrl: settings.API_BROWSER_BASE_URL,
    nodeBaseUrl: settings.API_NODE_BASE_URL,
    newNodeBaseUrl: settings.API_NEW_NODE_BASE_URL,
  });

  const baseQueryFn = fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers) => {
      headers.set('content-type', 'application/json; charset=utf-8');
      headers.set('accept', 'application/json');

      // attempt to set csrf cookie when in browser render mode
      if (!globals.IS_SERVER) {
        const csrfCookieName = settings.CSRF_COOKIE_NAME;
        const csrfCookie = csrfCookieName ? Cookies.get(csrfCookieName) : null;
        if (csrfCookie) {
          headers.set(settings.CSRF_HEADER_NAME, csrfCookie);
        }
      } else {
        headers.set('x-forwarded-host', settings.API_NODE_HTTP_HOST);
      }

      return headers;
    },
  });
  return baseQueryFn(args, api, extraOptions);
};

const api = createApi({
  reducerPath: 'api',
  baseQuery: query,
  endpoints: (builder) => ({
    getArticles: builder.query({
      query: () => '/api/articles/',
    }),
    getPopularMaps: builder.query({
      query: () => '/api/maps/?ordering=rating_asc&limit=50',
      transformResponse: (response) => {
        return response.results;
      },
    }),
    getPopularServers: builder.query({
      query: () => '/api/data-popular-servers/',
    }),
    getGame: builder.query({
      query: (gameId) => `/api/games/${gameId}/`,
    }),
    getGameHighlights: builder.query({
      query: (gameId) => `/api/games/${gameId}/highlights/`,
    }),
    getServers: builder.query({
      query: (filters) => {
        const queryParams = convertFiltersToQuery(filters);
        return `/api/servers/?${queryString.stringify(queryParams)}`;
      },
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: servers } = await queryFulfilled;
          for (const svr of servers) {
            dispatch(api.util.upsertQueryData('getServer', `${svr.address}`, svr));
          }
        } catch {}
      },
    }),
    getServer: builder.query({
      query: (address) => `/api/servers/${address}/`,
    }),
    addServer: builder.mutation({
      query: (payload) => ({
        url: '/api/servers/',
        method: 'POST',
        body: payload,
      }),
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          const { data: addedSvr } = await queryFulfilled;
          dispatch(api.util.upsertQueryData('getServer', `${addedSvr.address}`, addedSvr));
        } catch {}
      },
    }),
  }),
});

export const {
  endpoints,
  middleware,
  reducer,
  reducerPath,
  util,
  useGetArticlesQuery,
  useGetPopularMapsQuery,
  useGetPopularServersQuery,
  useGetGameQuery,
  useGetGameHighlightsQuery,
  useGetServersQuery,
  useGetServerQuery,
  useAddServerMutation,
} = api;

export default api;
