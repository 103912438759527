import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Button from 'app/components/Button';
import ChapterTitle from 'app/components/ChapterTitle';
import AppView from 'app/views/AppView';
import { setModal, ModalTypes } from 'app/store/modal';

import styles from './styles.less';

const SearchButton = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  return (
    <div className={styles.searchButton}>
      <Button
        style="white"
        title={t('Search')}
        onClick={() => dispatch(setModal(ModalTypes.SEARCH))}
      />
    </div>
  );
};

const HeaderLeft = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.navButton}>
      <Button style="left" linkTo="/" title={t('Main')} />
    </div>
  );
};

const HeaderRight = ({ children }) => <div className={styles.navButton}>{children}</div>;

const ChapterView = (props) => {
  const {
    children,
    title,
    navBar,
    headerMiddle,
    footerLeftLower,
    footerMiddle,
    footerRightUpper,
    backgroundImage,
  } = props;

  return (
    <AppView
      headerLeft={<HeaderLeft />}
      headerMiddle={headerMiddle}
      headerRight={<HeaderRight>{navBar}</HeaderRight>}
      footerLeftUpper={<SearchButton />}
      footerLeftLower={footerLeftLower}
      footerMiddle={footerMiddle}
      footerRightUpper={footerRightUpper}
      backgroundImage={backgroundImage}
    >
      <div className={styles.chapter}>
        {title && (
          <div className={styles.title}>
            <ChapterTitle title={title} />
          </div>
        )}
        <div className={styles.content}>{children}</div>
      </div>
    </AppView>
  );
};

ChapterView.propTypes = {
  title: PropTypes.string,
  backgroundImage: PropTypes.string,
  navBar: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  headerMiddle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerLeftLower: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerMiddle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerRightUpper: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node.isRequired,
};

export default ChapterView;
