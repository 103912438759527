import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { NavLink } from 'react-router-dom';

import styles from './styles.less';

const styleToClass = {
  button: styles.button,
  left: styles.arrowLeft,
  right: styles.arrowRight,
  exit: styles.exit,
  white: styles.white,
  submit: styles.submit,
  cancel: styles.cancel,
};

const Button = ({
  linkTo,
  href,
  title,
  style = 'button',
  onClick,
  className,
  counter,
  disabled,
}) => {
  const classNames = [styleToClass[style], className || '', { '-disabled': disabled }];
  const props = {
    className: cn(classNames),
  };
  if (onClick) {
    props.onClick = onClick;
  }
  if (counter) {
    props['data-counter'] = counter;
  }
  if (!disabled) {
    if (linkTo) {
      return <NavLink to={linkTo} end data-title={title} {...props} />;
    } else if (href) {
      return <a href={href} data-title={title} {...props} />;
    }
  }
  return <div data-title={title} {...props} />;
};

Button.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  style: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
