import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import YesNoButtonBar from 'app/components/YesNoButtonBar';
import { clearModal } from 'app/store/modal';
import { FormInputField } from 'app/components/form';
import { useAddServerMutation } from 'app/api';

import styles from './styles.less';

const AddServer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [payload, setPayload] = useState({
    ip: null,
    port: null,
  });
  const [errors, setErrors] = useState([]);

  const [addServer, { isLoading }] = useAddServerMutation();

  const handleInputChange = useCallback(
    (e) => {
      setPayload({
        ...payload,
        [e.target.name]: e.target.value,
      });
    },
    [payload],
  );

  const handleSubmit = useCallback(() => {
    addServer(payload)
      .unwrap()
      .then((data) => {
        const { ip, port } = data;
        const location = `/servers/${ip}:${port}/`;
        dispatch(clearModal());
        navigate(location);
      })
      .catch((resp) => {
        let errors = [];
        if (resp && resp.status === 400) {
          const errorData = resp.data;
          // merge non_field_errors
          if (errorData.non_field_errors) {
            errors = errors.concat(errorData.non_field_errors);
          } else if (Object.keys(errorData).length) {
            errors.push(t('Ensure the server address is correct'));
          }
        } else {
          errors.push(t('Unable to process your request at this time'));
        }
        setErrors(errors);
      });
  }, [addServer, payload, dispatch, navigate, t]);

  const handleCancel = useCallback(() => {
    dispatch(clearModal());
  }, [dispatch]);

  return (
    <div className={styles.container}>
      <div className={styles.errors}>
        {errors.map((error, index) => (
          <p key={index}>{error}</p>
        ))}
        {errors.length ? '' : <p className={styles.placeholder} />}
      </div>
      <div className={styles.fields}>
        <div className={styles.address}>
          <FormInputField
            autoFocus
            name="ip"
            label={t('Server IP')}
            placeholder="1.2.3.4"
            onChange={handleInputChange}
            readOnly={!!isLoading}
          />
        </div>
        <div className={styles.port}>
          <FormInputField
            name="port"
            label={t('Join Port')}
            placeholder="10480"
            type="number"
            onChange={handleInputChange}
            readOnly={!!isLoading}
          />
        </div>
      </div>
      <div className={styles.buttons}>
        <YesNoButtonBar onSubmit={handleSubmit} onCancel={handleCancel} />
      </div>
    </div>
  );
};

export default AddServer;
