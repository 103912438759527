import React from 'react';

import styles from './styles.less';

const ChapterTitle = ({ title }) => (
  <div className={styles.title}>
    <div>{title}</div>
  </div>
);

export default ChapterTitle;
