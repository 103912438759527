import React from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import CountryFlag from 'app/components/CountryFlag';
import CoopPaneBox from 'app/components/coop-ui/CoopPaneBox';
import { SkeletonText } from 'app/components/skeleton';

import styles from './styles.less';

const CoopTeamPlayerRow = ({
  name,
  ping,
  coop_status: coopStatus,
  coop_status_slug: coopStatusSlug,
  country,
  country_human: countryHuman,
}) => {
  const statusToClass = {
    injured: styles.injured,
    incapacitated: styles.incapacitated,
    healthy: styles.healthy,
    ready: styles.healthy,
    not_ready: styles.notReady,
  };
  return (
    <div className={styles.player}>
      <div className={styles.ping}>
        {country ? <CountryFlag iso={country} name={countryHuman} /> : ping}
      </div>
      <div className={styles.name}>{name}</div>
      <div className={classNames(styles.status, statusToClass[coopStatusSlug])}>{coopStatus}</div>
    </div>
  );
};

const CoopTeamPlayerRowSkeleton = () => (
  <div className={styles.player}>
    <div className={styles.ping}>
      <div className={styles.skeleton}>
        <SkeletonText extraClassName="w75" />
      </div>
    </div>
    <div className={styles.name}>
      <div className={styles.skeleton}>
        <SkeletonText extraClassName="w75" />
      </div>
    </div>
    <div className={styles.status}>
      <div className={styles.skeleton}>
        <SkeletonText extraClassName="w50" />
      </div>
    </div>
  </div>
);

const PlayerList = ({ players }) => {
  if (!players) {
    return [...Array(3)].map((_, i) => <CoopTeamPlayerRowSkeleton key={i} />);
  }
  return players.map((player) => <CoopTeamPlayerRow key={player.id} {...player} />);
};

const CoopTeamPane = ({ players, extraClassName }) => {
  const { t } = useTranslation();
  return (
    <div className={classNames(styles.team, extraClassName)}>
      <CoopPaneBox title={t('Team Status')}>
        <PlayerList players={players} />
      </CoopPaneBox>
    </div>
  );
};

export default CoopTeamPane;
