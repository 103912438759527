import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import ChapterTitle from 'app/components/ChapterTitle';

import styles from './styles.less';

const LoadingScreen = ({ noTitle }) => {
  const { t } = useTranslation();
  const [fillStep, setFillStep] = useState(5);

  useEffect(() => {
    const fillSteps = [15, 20, 45, 75];
    const fill = () => {
      const nextStep = fillSteps.shift();
      if (!nextStep) {
        clearInterval(timer);
      } else {
        setFillStep(nextStep);
      }
    };
    const timer = setInterval(() => fill(), 750);
    // stop the timer before unmounting of the component
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={styles.screen}>
      <div className={styles.picture} />
      <div className={styles.title}>{noTitle ? null : <ChapterTitle title={t('Loading')} />}</div>
      <div className={styles.barContainer}>
        <div className={styles.barText}>{t('Loading...')}</div>
        <div className={styles.bar}>
          <div className={styles.barFilled} style={{ width: `${fillStep}%` }} />
        </div>
      </div>
    </div>
  );
};

export default LoadingScreen;
