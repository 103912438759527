import React from 'react';
import { BrowserRouter, useRoutes } from 'react-router-dom';
import 'whatwg-fetch';

import makeRoutes from 'app/routes';

const Routes = () => {
  return useRoutes(makeRoutes());
};

export default function BrowserApp() {
  return (
    <BrowserRouter>
      <Routes />
    </BrowserRouter>
  );
}
