import React from 'react';
import Button from 'app/components/Button';

import styles from './styles.less';

const YesNoButtonBar = ({ onSubmit, onCancel }) => (
  <div className={styles.yesNoButtonBar}>
    <div className={styles.yes}>
      <Button onClick={onSubmit || null} style="submit" />
    </div>
    <div className={styles.no}>
      <Button onClick={onCancel || null} style="cancel" />
    </div>
  </div>
);

export default YesNoButtonBar;
