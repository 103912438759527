import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import Logo from 'app/components/Logo';

import styles from './styles.less';

export const MenuMockState = {
  INITIAL: 'INITIAL',
  SELECT_JOIN_GAME: 'SELECT_JOIN_GAME',
};

const MainPageMenu = ({ state }) => {
  const { t } = useTranslation();

  const isJoinGameActive = state === MenuMockState.SELECT_JOIN_GAME;

  return (
    <div className={styles.menu}>
      <div className={styles.logoContainer}>
        <Logo />
      </div>
      <ul>
        <li className={styles.divider}>{t('Single Player Game')}</li>
        <li className={styles.heading}>{t('Career')}</li>
        <li className={styles.heading}>{t('Training')}</li>
        <li className={styles.heading}>{t('Instant Action')}</li>
        <li className={styles.divider}>{t('Create And Play Your Own Missions')}</li>
        <li className={styles.heading}>{t('Play Quick Mission')}</li>
        <li className={styles.heading}>{t('Quick Mission Maker')}</li>
        <li className={styles.divider}>{t('Multiplayer Game')}</li>
        <li className={styles.heading}>{t('Host Game')}</li>
        <li>
          <div className={cn(styles.heading, { active: isJoinGameActive })}>{t('Join Game')}</div>
        </li>
        <li className={styles.divider} />
        <li className={styles.heading}>{t('Settings')}</li>
        <li className={styles.heading}>{t('Credits')}</li>
      </ul>
    </div>
  );
};

const MainMenuMock = ({ state }) => {
  const frameStyle = {
    backgroundImage: "url('/static/images/maps/background/intro.jpg')",
  };
  return (
    <div className={styles.container}>
      <div className={styles.frame} style={frameStyle}>
        <MainPageMenu state={state} />
      </div>
    </div>
  );
};

export default MainMenuMock;
