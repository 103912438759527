import { configureStore, isRejectedWithValue, Tuple } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';

import safeSettings from 'app/store/safeSettings';
import meta, { setErrorCode } from 'app/store/meta';
import modal from 'app/store/modal';
import gameList from 'app/store/gameList';
import serverList from 'app/store/serverList';
import serverDetail from 'app/store/serverDetail';
import {
  middleware as apiMiddleware,
  reducer as apiReducer,
  reducerPath as apiReducerPath,
} from 'app/api';

export const queryErrorHandler = ({ dispatch }) => {
  return (next) => {
    return (action) => {
      if (isRejectedWithValue(action)) {
        dispatch(setErrorCode(action.payload.status));
      }
      return next(action);
    };
  };
};

export default () => {
  const initialState = window?.__PRELOADED_STATE__ || {};
  return configureStore({
    reducer: {
      meta,
      safeSettings,
      modal,
      gameList,
      serverList,
      serverDetail,
      [apiReducerPath]: apiReducer,
    },
    preloadedState: initialState,
    middleware: () => new Tuple(thunk, apiMiddleware, queryErrorHandler),
  });
};
