import React from 'react';
import PropTypes from 'prop-types';

import ModalManager from 'app/views/ModalManager';

import styles from './styles.less';

const AppView = (props) => {
  const {
    children,
    backgroundImage = '/static/images/maps/background/intro.jpg',
    headerLeft,
    headerMiddle,
    headerRight,
    footerLeftUpper,
    footerLeftLower,
    footerMiddle,
    footerRightUpper,
  } = props;

  const backgroundStyle = {
    backgroundImage: `url(${backgroundImage})`,
  };

  return (
    <>
      <ModalManager />
      <div className={styles.siteContainer}>
        <div className={styles.site} style={backgroundStyle}>
          <div className={styles.header}>
            <div className={styles.left}>{headerLeft}</div>
            <div className={styles.middle}>{headerMiddle}</div>
            <div className={styles.right}>{headerRight}</div>
          </div>
          <div className={styles.content}>{children}</div>
          <div className={styles.footer}>
            <div className={styles.left}>
              <div className={styles.leftUpper}>{footerLeftUpper}</div>
              <div className={styles.leftLower}>{footerLeftLower}</div>
            </div>
            <div className={styles.middle}>{footerMiddle}</div>
            <div className={styles.right}>
              <div className={styles.rightUpper}>{footerRightUpper}</div>
              <div className={styles.rightCopy}>
                <p>
                  &copy; 2013-{new Date().getFullYear()}{' '}
                  <a href="https://github.com/sergeii/">Serge</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

AppView.propTypes = {
  backgroundImage: PropTypes.string,
  headerLeft: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  headerMiddle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  headerRight: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerLeftUpper: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerLeftLower: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerMiddle: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  footerRightUpper: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.node.isRequired,
};

export default AppView;
