import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import ChapterTitle from 'app/components/ChapterTitle';
import ContentBox from 'app/components/ContentBox';
import { useGetServerQuery } from 'app/api';

import ServerDetailJoinTutorial, { TutorialState } from './ServerDetailJoinTutorial';
import ServerDetailJoinGuide, { GuideStep } from './ServerDetailJoinGuide';
import styles from './styles.less';

const guideSteps = [
  {
    guideStep: GuideStep.COPY_ADDRESS,
    tutorialState: TutorialState.MAIN_MENU_OPEN,
  },
  {
    guideStep: GuideStep.LAUNCH_GAME,
    tutorialState: TutorialState.MAIN_MENU_OPEN,
  },
  {
    guideStep: GuideStep.JOIN_GAME,
    tutorialState: TutorialState.MAIN_MENU_JOIN_GAME,
  },
  {
    guideStep: GuideStep.JOIN_GAME,
    tutorialState: TutorialState.SERVER_BROWSER_OPEN,
  },
  {
    guideStep: GuideStep.CLICK_DIRECT,
    tutorialState: TutorialState.SERVER_BROWSER_FORM_OPEN,
  },
  {
    guideStep: GuideStep.ENTER_ADDRESS,
    tutorialState: TutorialState.SERVER_BROWSER_FORM_ENTER,
  },
  {
    guideStep: GuideStep.CLICK_OK,
    tutorialState: TutorialState.SERVER_BROWSER_FORM_SUBMIT,
  },
  {
    guideStep: GuideStep.ENJOY,
    tutorialState: TutorialState.LOADING_SCREEN,
  },
];

const ServerDetailJoin = () => {
  const { t } = useTranslation();
  const { address } = useParams();
  const [step, setStep] = useState(0);
  const { data: server } = useGetServerQuery(address);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const nextStep = step === guideSteps.length - 1 ? 0 : step + 1;
      setStep(nextStep);
    }, 3000);
    return () => {
      clearInterval(intervalId);
    };
  }, [step]);

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <ChapterTitle title={t('Join Game')} />
      </div>
      <div className={styles.contentBox}>
        <ContentBox bordered>
          <div className={styles.content}>
            <div className={styles.tutorial}>
              <ServerDetailJoinTutorial
                state={guideSteps[step].tutorialState}
                address={server?.address}
              />
            </div>
            <div className={styles.guide}>
              <ServerDetailJoinGuide address={server?.address} step={guideSteps[step].guideStep} />
            </div>
          </div>
        </ContentBox>
      </div>
    </div>
  );
};

export default ServerDetailJoin;
