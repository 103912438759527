import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ContentBox from 'app/components/ContentBox';
import CoopPaneBox from 'app/components/coop-ui/CoopPaneBox';
import CoopTeamPane from 'app/components/coop-ui/CoopTeamPane';
import CoopObjectivesPane from 'app/components/coop-ui/CoopObjectivesPane';
import nl2br from 'app/utils/text/nl2br';
import { SkeletonText } from 'app/components/skeleton';

import styles from './styles.less';

const BriefingSkeleton = () => (
  <div className={styles.skeleton}>
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w75" />
    <SkeletonText extraClassName="w50" />
    <div>&nbsp;</div>
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w75" />
    <div>&nbsp;</div>
    <SkeletonText extraClassName="w100" />
    <SkeletonText extraClassName="w75" />
  </div>
);

const CoopBriefingPane = ({ server }) => {
  return (
    <div className={classNames(styles.briefing, { [styles.noBriefing]: !server.status.briefing })}>
      <ContentBox scrollable title={server.status.mapname}>
        <div className={styles.paneContent}>
          {server.status.briefing !== undefined ? (
            nl2br(server.status.briefing || '')
          ) : (
            <BriefingSkeleton />
          )}
        </div>
      </ContentBox>
    </div>
  );
};

const CoopProcedureRow = ({ title, score }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.procedure}>
      <div className={styles.title}>{title}</div>
      <div className={styles.score}>{score || t('n/a')}</div>
    </div>
  );
};

const CoopProceduresPane = ({ server }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.procedures}>
      <CoopPaneBox title={t('Procedures')}>
        <CoopProcedureRow title={t('Report status to TOC')} score={server.status.coop_reports} />
        <CoopProcedureRow title={t('All weapons secured')} score={server.status.coop_weapons} />
      </CoopPaneBox>
    </div>
  );
};

const ServerDetailCoop = (props) => {
  const { server } = props;

  const noObjectives = server.status.objectives && !server.status.objectives.length;
  const noTeam = server.status.players && !server.status.players.length;

  return (
    <div className={styles.frame}>
      <div className={styles.pane}>
        <CoopObjectivesPane
          extraClassName={classNames(styles.objectives, { [styles.noObjectives]: noObjectives })}
          objectives={server.status.objectives}
        />
        <CoopTeamPane
          extraClassName={classNames(styles.team, { [styles.noTeam]: noTeam })}
          players={server.status.players}
        />
      </div>
      <div className={styles.pane}>
        <CoopBriefingPane {...props} />
        <CoopProceduresPane {...props} />
      </div>
    </div>
  );
};

export default ServerDetailCoop;
