import { createSlice } from '@reduxjs/toolkit';

const defaultMetaTitle = 'SWAT 4 Stats - Live Server Browser, Player Leaderboards & Statistics';
const defaultMetaDescription =
  'SWAT 4 Stats is the operational hub for the SWAT 4 community, ' +
  'providing critical server surveillance, in-depth player analysis, ' +
  'and tactical records from past operations. ' +
  'Exercise caution when browsing servers and ' +
  'evaluating player performance to improve your in-game tactics.';

const initialState = {
  errorCode: null,
  pageMeta: {
    title: defaultMetaTitle,
    description: defaultMetaDescription,
  },
};

const slice = createSlice({
  name: 'meta',
  initialState,
  reducers: {
    setErrorCode: (state, action) => {
      state.errorCode = action.payload;
    },
    setPageMeta: (state, action) => {
      const { title, description } = action.payload ?? {};
      state.pageMeta.title = title ? `${title} · SWAT 4 Stats` : defaultMetaTitle;
      state.pageMeta.description = description ?? defaultMetaDescription;
      // change the document title if we're in the browser
      if (document !== undefined) {
        document.title = state.pageMeta.title;
      }
    },
  },
});

export const { setErrorCode, setPageMeta } = slice.actions;

export default slice.reducer;
