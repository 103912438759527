import { setPageMeta } from 'app/store/meta';

const getMetaTitleForServer = (server) => {
  const serverHostname = server?.status?.hostname_clean || server.name_clean || server.address;
  switch (server?.status?.gametype_slug) {
    case 'rapid-deployment':
      return `${serverHostname} · Tactical Bomb Showdown`;
    case 'vip-escort':
      return `${serverHostname} · Escort or Ambush`;
    case 'smash-and-grab':
      return `${serverHostname} · Heist Under Pressure`;
    case 'co-op':
      return `${serverHostname} · Teamwork Triumph`;
    default:
      return `${serverHostname} · SWAT vs. Suspects Showdown`;
  }
};

const getMetaDescriptionForServer = (server) => {
  const serverHostname = server?.status?.hostname_clean || server.address;
  switch (server?.status?.gametype_slug) {
    case 'rapid-deployment':
      return (
        `Join ${serverHostname} for the ultimate test of skill in defusing or defending bombs. ` +
        `As SWAT, use your toolkit to neutralize the threat, ` +
        `while suspects work to prevent disarmament. ` +
        `Experience intense PvP action and showcase your ` +
        `tactical prowess in these high-stakes scenarios.`
      );
    case 'vip-escort':
      return (
        `Join ${serverHostname} for a high-stakes mission of VIP extraction. ` +
        `As SWAT, strategize and protect your VIP to secure their escape, ` +
        `while suspects seek to capture and hold them hostage. ` +
        `Test your tactical skills and make crucial decisions in this thrilling PvP experience.`
      );
    case 'smash-and-grab':
      return (
        `Join ${serverHostname} for an adrenaline-pumping smash and grab challenge. ` +
        `As suspects, attempt to secure the briefcase and make a daring escape. ` +
        `As officers, prevent their success and apprehend the criminals. ` +
        `Experience high-stakes combat and fast-paced action in this PvP battle against time.`
      );
    case 'co-op':
      return (
        `Team up with fellow players on ${serverHostname} and take on challenging co-op missions. ` +
        `Work together as a SWAT team to outsmart hostile suspects, ` +
        `protect civilians, and secure the area. ` +
        `Test your coordination and strategy in this immersive SWAT 4 cooperative experience.`
      );
    default:
      return (
        `Join ${serverHostname} for intense SWAT vs. Suspects showdowns. ` +
        `Battle for supremacy in high-stakes situations, neutralize threats, and secure arrests. ` +
        `Showcase your tactical expertise and decision-making in these thrilling PvP encounters.`
      );
  }
};

export const preparePageMetaAction = (server) => {
  if (!server)
    return setPageMeta({
      title: 'SWAT vs. Suspects Showdown',
    });
  return setPageMeta({
    title: getMetaTitleForServer(server),
    description: getMetaDescriptionForServer(server),
  });
};
