import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import cn from 'classnames';

import Button from 'app/components/Button';
import { FormInputField } from 'app/components/form';

import styles from './styles.less';

export const GuideStep = {
  COPY_ADDRESS: 'COPY_ADDRESS',
  LAUNCH_GAME: 'LAUNCH_GAME',
  JOIN_GAME: 'JOIN_GAME',
  CLICK_DIRECT: 'CLICK_DIRECT',
  ENTER_ADDRESS: 'ENTER_ADDRESS',
  CLICK_OK: 'CLICK_OK',
  ENJOY: 'ENJOY',
};

const JoinGameCaption = () => {
  const { t } = useTranslation();
  return <div className={styles.joinGameCaption}>{t('Join Game')}</div>;
};

const DirectButton = ({ isActive }) => {
  const { t } = useTranslation();
  return (
    <div className={styles.directButtonContainer}>
      <Button
        style="white"
        title={t('Direct IP')}
        className={cn(styles.directButton, { active: isActive })}
      />
    </div>
  );
};

const OKButton = ({ isActive }) => {
  return (
    <div className={styles.joinButtonContainer}>
      <Button style="submit" className={cn('-small', { active: isActive })} />
    </div>
  );
};

const AddressField = ({ address }) => {
  return (
    <div className={styles.serverAddress}>
      <FormInputField value={address || ''} onFocus={(e) => e.target.select()} readOnly />
    </div>
  );
};

const ServerDetailJoinGuide = ({ address, step }) => {
  const { t } = useTranslation();
  const is = (checkStep) => {
    return step === checkStep;
  };
  return (
    <div>
      <div className={styles.introduction}>
        <p>To join the server, follow these simple steps:</p>
      </div>
      <ul className={styles.steps}>
        <li className={cn({ active: is(GuideStep.COPY_ADDRESS) })}>
          <div>{t('Copy the server address:')}</div>
          <AddressField address={address} />
        </li>
        <li className={cn({ active: is(GuideStep.LAUNCH_GAME) })}>
          {t('Launch SWAT 4 and open the main menu')}
        </li>
        <li className={cn({ active: is(GuideStep.JOIN_GAME) })}>
          <Trans>
            Select <JoinGameCaption /> to access the server browser
          </Trans>
        </li>
        <li className={cn({ active: is(GuideStep.CLICK_DIRECT) })}>
          <Trans>
            Click <DirectButton isActive={is(GuideStep.CLICK_DIRECT)} /> at the bottom of the screen
          </Trans>
        </li>
        <li className={cn({ active: is(GuideStep.ENTER_ADDRESS) })}>
          {t('Paste the copied server address in the form')}
        </li>
        <li className={cn({ active: is(GuideStep.CLICK_OK) })}>
          <Trans>
            Click <OKButton isActive={is(GuideStep.CLICK_OK)} /> to connect to the server
          </Trans>
        </li>
        <li className={cn({ active: is(GuideStep.ENJOY) })}>{t('Enjoy your game!')}</li>
      </ul>
    </div>
  );
};

export default ServerDetailJoinGuide;
