import React from 'react';
import cn from 'classnames';

import styles from './styles.less';

export const SkeletonTitle = ({ extraClassName }) => (
  <div className={cn(styles.title, extraClassName)}>&nbsp;</div>
);

export const SkeletonText = ({ extraClassName }) => (
  <div className={cn(styles.text, extraClassName)}>&nbsp;</div>
);

export const SkeletonParagraph = ({ extraClassName }) => (
  <div className={cn(styles.paragraph, extraClassName)}>&nbsp;</div>
);

export const SkeletonImage = ({ extraClassName }) => (
  <div className={cn(styles.image, extraClassName)}>&nbsp;</div>
);
